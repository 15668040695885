import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import addressApis from "../../../apis/river-hospitality/addressApis/addressApis";

export const getProvince = createAsyncThunk(
  "/address/getProvince",
  async (_, { dispatch }) => {
    try {
      const res = await addressApis.getProvince();
      return res;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getDistrict = createAsyncThunk(
  "/address/getDistrict",
  async (provinceId, { dispatch }) => {
    try {
      const res = await addressApis.getDistrict(provinceId);
      return res;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getWard = createAsyncThunk(
  "/address/getWard",
  async (districtId, { dispatch }) => {
    try {
      const res = await addressApis.getWard(districtId);
      return res;
    } catch (error) {
      console.log(error);
    }
  }
);

export const suggestAdress = createAsyncThunk(
  "/address/suggestAdress",
  async (data, { dispatch }) => {
    try {
      const res = await addressApis.suggestAdress(data);
      return res;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  wardList: [],
  suggestData: "",
  provinceList: [],
  districtList: [],
};

export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {},
  extraReducers: {
    [getProvince.fulfilled.type]: (state, action) => {
      state.provinceList = action.payload?.data?.getProvince;
    },

    [getDistrict.fulfilled.type]: (state, action) => {
      state.districtList = action.payload?.data?.getDistrict;
    },

    [getWard.fulfilled.type]: (state, action) => {
      state.wardList = action.payload?.data?.getWard;
    },

    [suggestAdress.fulfilled.type]: (state, action) => {
      state.suggestData = action.payload?.data?.suggestAdress;
    },
  },
});

export default addressSlice.reducer;
