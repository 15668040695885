import { Layout } from "@douyinfe/semi-ui";
import React, { Suspense, useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/vi";

import Contentlayout from "./contentLayout";
import useResponsive from "../../hooks/useResponsive";
// import BreadcrumbBar from "../component/common/breadCrumb/breadCrumb";
import classNames from "classnames";

import "./mainLayout.scss";
import MainHeader from "./header/header";
import MainSider from "../common/navBar/mainSider";
import LazyLoading from "../common/lazyLoading/lazyLoading";
import ResponsiveMenu from "../common/ResponsiveMenu";
import FooterComponent from "../common/footer/footer";
import routes from "../../routes/routes";

moment.locale("vi");

const { Content } = Layout;

const MainLayout = () => {
  const { screens } = useResponsive();
  const { isMobile, isTablet } = screens;

  const [isShowSideBar, setIsShowSideBar] = useState(false);

  const renderLayout = (routes, Layout) => {
    return routes.map((route, index) => {
      const { component, exact, path } = route;
      return (
        <Layout key={index} component={component} exact={exact} path={path} />
      );
    });
  };

  const closeMenu = () => {
    if ((isMobile || isTablet) && isShowSideBar) {
      setIsShowSideBar(false);
    }
  };

  useEffect(() => {
    if (isMobile || isTablet) {
      setIsShowSideBar(false);
      return;
    }

    if (!isMobile && !isTablet) {
      setIsShowSideBar(true);
    }
  }, [isMobile, isTablet]);

  return (
      <Layout>
        <MainHeader
          isShow={isShowSideBar}
          onShowSide={() => setIsShowSideBar(!isShowSideBar)}
        />
        <Layout>
          <div
            id="side-bar"
            className={classNames(["side-bar", { hide: !isShowSideBar }])}
          >
            <MainSider onClick={closeMenu} />
          </div>

          <Content
            className={classNames([
              'main-content',
              { "mobile-view-toggle": isShowSideBar && (isMobile || isTablet) },
            ])}
          >
            {/* <BreadcrumbBar /> */}

            <div style={{ position: "relative" }} className="main-route">
              <Suspense fallback={<LazyLoading />}>
                {renderLayout(routes, Contentlayout)}
              </Suspense>
              <div className="responsiveBar">
                <ResponsiveMenu />
              </div>
            </div>
          </Content>
        </Layout>
        <FooterComponent />
      </Layout>
  );
};

export default MainLayout;
