import authour from "./auth/authour.gql";
import user from "./riverLand/user/user.gql";
import company from "./riverLand/company/company.gql";
import department from "./riverLand/department/department.gql";
import feedback from "./riverStream/feedback/feedback.gql";
import topSearch from "./riverStream/topSearch/topSearch.gql";
import hastag from "./riverStream/topHashTag/topHashtag.gql";
import interested from "./riverStream/interested/interested.gql";
import RvsStream from "./riverStream/rvsStream/RvsStream.gql";
import RvsVideo from "./riverStream/rvsVideo/RvsVideo.gql";
import streamReport from "./riverStream/streamReport/streamReport.gql";
import category from "./riverLand/category/category.gql";
import card from "./riverLand/card/card.gql";
import projectTag from "./riverGroup/projectTag/projectTag.gql";
import rvGroupProject from "./riverGroup/rvsGroupProject/rvGroupProject.gql";
import iconProject from "./riverGroup/iconProject/iconProject.gql";
import rvMakerProject from "./riverGroup/rvsMarkerProject/rvMakerProject.gql";
import rvStreamProject from "./riverGroup/rvsStreamProject/rvStreamProject.gql";
import newsCategory from "./riverGroup/newsCategory/newsCategory.gql";
import phoneBook from "./riverMarker/phoneBook/phonebook.gql";
import newsList from "./riverLand/news/newsList.gql";
import workFlow from "./riverMarker/workflow/workflow.gql";
import appointment from "./riverMarker/appointment/appointment.gql";
import realEstateProject from "./riverMarker/realEstateProject/realEstateProject.gql";
import address from "./riverLand/address.gql";
import contact from "./riverLand/contactResquest/contactRequest.gql";
import document from "./riverLand/document/document.gql";
import news from "./riverGroup/news/news.gql";

import rhNews from "./riverHospitality/news/news.gql";
import hotel from "./riverHospitality/hotel/hotel.gql";
import camping from "./riverHospitality/camping/camping.gql";

import staff from "./riverHospitality/staff/staff.gql";
import rhUser from "./riverHospitality/user/rhUser.gql";

import permission from "./permission/permission.gql";

const actionGraphQL = {
  authour,
  contact,
  user,
  company,
  department,
  document,
  // riverStream
  feedback,
  hastag,
  interested,
  topSearch,
  RvsStream,
  RvsVideo,
  streamReport,
  // riverMaker
  phoneBook,
  workFlow,
  appointment,
  realEstateProject,
  address,
  // riverLand
  category,
  card,
  newsList,
  // riverGroup
  projectTag,
  rvGroupProject,
  iconProject,
  rvMakerProject,
  rvStreamProject,
  newsCategory,
  news,

  // riverHospitality
  rhNews,
  hotel,
  camping,
  staff,
  rhUser,

  permission,
};
export default actionGraphQL;
