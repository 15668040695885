import { gql } from "@apollo/client";
const action = {
  getAllFeedback: gql`
    query GetAllFeedback($name: String, $page: Int, $limit: Int) {
      getAllFeedback(
        q: {
          search: $name
          page: $page
          limit: $limit
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          content
          contact
          type
          createdAt
        }
        total
      }
    }
  `,
};

export default action;
