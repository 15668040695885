import React from "react";

export const planningRoutes = [
  {
    exact: true,
    key: "planning-request",
    path: `/planning-request-list`,
    component: React.lazy(() => import("../../pages/planning-request/index.js" )),
  },

  {
    exact: true,
    key: "planning-information",
    path: `/planning-information-list`,
    component: React.lazy(() => import("../../pages/planning-info/index.js" )),
  },
];
