import { gql } from "@apollo/client";
const action = {
  getAllTopHashTag: gql`
    query GetAllTopHashTag($page: Int, $limit: Int) {
      getAllTopHashTag(
        q: { page: $page, limit: $limit, order: { createdAt: -1 } }
      ) {
        data {
          id
          key
          createdAt
        }
        total
      }
    }
  `,
  deleteOneTopHashTag: gql`
    mutation DeleteOneTopHashTag($id: ID!) {
      deleteOneTopHashTag(id: $id) {
        id
      }
    }
  `,
  createTopHashTag: gql`
    mutation CreateTopHashTag($data: String) {
      createTopHashTag(data: { key: $data }) {
        id
      }
    }
  `,
};

export default action;
