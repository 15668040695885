import { IconBell, IconExit, IconSetting } from "@douyinfe/semi-icons";
import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  Nav,
  Switch,
  Modal,
  Toast,
} from "@douyinfe/semi-ui";
import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from "react";
import { ThemeModeContext, UserDataContext } from "../../../App";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import actionsGraphQL from "../../../graphql";

import { storage } from "../../../firebase/index";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

import Label from "@douyinfe/semi-ui/lib/es/form/label";
import { useDispatch } from "react-redux";
import { authSlice } from "../../../redux/auth/authStore";

import { IconIndentLeft, IconIndentRight } from '@douyinfe/semi-icons';
import useResponsive from "../../../hooks/useResponsive";
import LandMarkerDartLogo from "../../../assets/images/logos/land-marker-dark-logo";
import LandMarkerLogo from "../../../assets/images/logos/land-marker-logo";

import './index.scss';

const { Header } = Layout;

function MainHeader({ isShow = false, onShowSide }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { screens } = useResponsive();
  const { isMobile, isTablet } = screens;

  const { logOut } = authSlice.actions;

  const { userData, setUserDataContext } = useContext(UserDataContext);
  const { setThemeModeContext } = useContext(ThemeModeContext);
  // state
  const [theme, setTheme] = useState(false); // true: dark; false: light
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [isModalShow, setIsModalShow] = useState(false);

  const imageUploadRef = useRef(null);

  const [updateMe, { loading: ld_update, data: updateUserInfo }] = useMutation(
    actionsGraphQL.user.updateMe,
    {
      fetchPolicy: "no-cache",
    }
  );

  const userId = userData?.data?.id;

  const isShowToggleIcon = useMemo(() => {
    return isMobile || isTablet;
  }, [isMobile, isTablet]);

  const ToggleIcon = useCallback(() => {
    if (!isShowToggleIcon) {
      return ''
    }

    if (isShow) {
      return <IconIndentLeft style={{ color: "var(--semi-color-text-0)" }} />
    }

    return <IconIndentRight style={{ color: "var(--semi-color-text-0)" }} />
  }, [isShowToggleIcon, isShow]);

  const switchMode = () => {
    const body = document.body;
    var bodyStyles = document.body.style;
    if (body.hasAttribute("theme-mode")) {
      body.removeAttribute("theme-mode");
      bodyStyles.removeProperty("--bgMainRoute");
      bodyStyles.setProperty("--semi-color-bg-0", "rgba(0, 0, 0, 2%)");
      setThemeModeContext({ mode: "light" });
      setTheme(false);
      localStorage.removeItem("semi-theme");
      // Notify our site to update current mode
      if (typeof window.setMode === "function") {
        window.setMode("light");
      }
    } else {
      body.setAttribute("theme-mode", "dark");
      bodyStyles.setProperty("--bgMainRoute", "rgba(225, 225, 225, 4%)");
      bodyStyles.removeProperty("--semi-color-bg-0");
      setThemeModeContext({ mode: "dark" });
      setTheme(true);
      localStorage.setItem("semi-theme", "dark");
      // Notify our site to update current mode
      if (typeof window.setMode === "function") {
        window.setMode("dark");
      }
    }
  };

  const handleToggle = () => {
    onShowSide?.()
  };

  function doLogOut() {
    dispatch(logOut());
    history.push("/sign-in");
  }

  const handleDisplayAvatarImage = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    const imageLocalUrl = URL.createObjectURL(file);
    setImage(imageLocalUrl);
  };

  const handleUploadImage = () => {
    console.log(imageUploadRef);
    imageUploadRef.current.click();
  };

  // const uploadImageToStore = async () => {

  // }

  const handleUpdateAvatar = () => {
    const storageRef = ref(
      storage,
      `AdminPage/${userId}/avatar/${imageFile.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, imageFile);
    uploadTask.on(
      "state_change",
      (snapshot) => { },
      (err) => Toast.error("Lỗi tải ảnh lên. Xin thử lại"),
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        console.log(url);
        updateMe({ variables: { data: { avatar: url } } });
      }
    );
  };

  const handleChangeUserLoginInfo = (dataUser) => {
    setUserDataContext({ ...userData, data: { ...dataUser } });
    handleReset();
  };

  const handleReset = () => {
    imageUploadRef.current.value = "";
    setIsModalShow(false);
    setImage("");
    setImageFile(null);
  };

  useEffect(() => {
    if (!!image) {
      setIsModalShow(true);
    }
  }, [image]);

  useEffect(() => {
    if (!!updateUserInfo) {
      handleChangeUserLoginInfo(updateUserInfo.updateMe);
    }
  }, [updateUserInfo]);

  useEffect(() => {
    if (localStorage.getItem("semi-theme") === "dark") {
      const body = document.body;
      var bodyStyles = document.body.style;
      body.setAttribute("theme-mode", "dark");
      bodyStyles.setProperty("--bgMainRoute", "rgba(225, 225, 225, 4%)");
      bodyStyles.removeProperty("--semi-color-bg-0");
      setTheme(true);
    }
  }, []);
  return (
    <>
      <Nav mode="horizontal" defaultSelectedKeys={["Home"]}>
        <Nav.Header className="header-logo">
          {!theme ? <LandMarkerDartLogo /> : <LandMarkerLogo />}
        </Nav.Header>

        <div className="header__hamburger" onClick={handleToggle}>
          <ToggleIcon />
        </div>

        <Nav.Footer>
          <Switch
            style={{ marginRight: "6px" }}
            checked={theme}
            onChange={switchMode}
            checkedText="L"
            uncheckedText="D"
          />
          {/* <Button
              theme="borderless"
              icon={<IconBell size="large" />}
              style={{
                color: "var(--semi-color-text-2)",
                marginRight: "6px",
              }}
            /> */}
          <Dropdown
            style={{ width: "200px" }}
            trigger={"click"}
            position={"bottomLeft"}
            render={
              <Dropdown.Menu>
                <Dropdown.Item style={{ justifyContent: "center" }}>
                  <Label>{userData?.data?.name}</Label>
                </Dropdown.Item>
                <Dropdown.Item onClick={handleUploadImage}>
                  <IconSetting /> Cập nhật ảnh đại diện
                </Dropdown.Item>
                <Dropdown.Item onClick={doLogOut}>
                  <IconExit /> Đăng xuất
                </Dropdown.Item>
              </Dropdown.Menu>
            }
          >
            <Avatar
              color="orange"
              src={userData?.data?.avatar}
              size="small"
            ></Avatar>
          </Dropdown>
        </Nav.Footer>
      </Nav>
      <input
        ref={imageUploadRef}
        onChange={handleDisplayAvatarImage}
        style={{ display: "none" }}
        type="file"
        accept="image/*"
      />
      <Modal
        // title="Cập nhật ảnh đại diện"
        visible={isModalShow}
        onCancel={() => {
          handleReset();
        }}
        hasCancel={true}
        confirmLoading={ld_update}
        footer={
          <>
            <Button onClick={handleUploadImage}>Chọn ảnh khác</Button>
            <Button loading={ld_update} onClick={handleUpdateAvatar}>
              Cập nhật
            </Button>
          </>
        }
      >
        <div className="imageUpload">
          <img src={image} alt="admin-avatar" />
        </div>
      </Modal>
    </>
  );
}

export default MainHeader;
