import client from "../../config/apolloClient";
import adressActions from "../../models/address";

const addressApis = {
  getProvince: () => {
    return client.query({
      query: adressActions.getProvince,
      variables: {},
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },

  getDistrict: (provinceId) => {
    return client.query({
      query: adressActions.getDistrict,
      variables: {
        provinceId,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },

  getWard: (districtId) => {
    return client.query({
      query: adressActions.getWard,
      variables: {
        districtId,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },

  suggestAdress: ({ latitude, longitude }) => {
    return client.query({
      query: adressActions.suggestAdress,
      variables: {
        latitude,
        longitude,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },
};

export default addressApis;
