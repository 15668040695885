import client from "../../config/apolloClient";
import actionGraphQL from "../../graphql";

const authApi = {
  login: (data) => {
    return client.mutate({
      mutation: actionGraphQL.authour.loginUser,
      variables: {
        username: data.username,
        password: data.password,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },
  checkLoginToken: () => {
    return client.query({
      query: actionGraphQL.authour.getMe,
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },
};

export default authApi;
