import { gql } from "@apollo/client";

const action = {
  getAllTypeCompanyPhoneBook: gql`
    query getAllTypeCompanyPhoneBook($page: Int, $limit: Int) {
      getAllTypeCompanyPhoneBook(
        q: { page: $page, limit: $limit, order: { createdAt: -1 } }
      ) {
        data {
          id
          name
          type
          createdAt
          updatedAt
        }
        total
      }
    }
  `,
  createTypeCompanyPhoneBook: gql`
    mutation createTypeCompanyPhoneBook(
      $data: CreateTypeCompanyPhoneBookInput!
    ) {
      createTypeCompanyPhoneBook(data: $data) {
        id
      }
    }
  `,
  updateTypeCompanyPhoneBook: gql`
    mutation updateTypeCompanyPhoneBook(
      $id: ID!
      $data: UpdateTypeCompanyPhoneBookInput!
    ) {
      updateTypeCompanyPhoneBook(id: $id, data: $data) {
        id
      }
    }
  `,
  getAllUserPhoneBook: gql`
    query getAllUserPhoneBook($page: Int, $limit: Int, $name: String) {
      getAllUserPhoneBook(
        q: {
          page: $page
          limit: $limit
          search: $name
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          name
          phone
          email
          description
          userCreated {
            id
            name
          }
          userCreatedId
          createdAt
          updatedAt
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,
  createUserPhoneBook: gql`
    mutation createUserPhoneBook($data: CreateUserPhoneBookInput!) {
      createUserPhoneBook(data: $data) {
        id
      }
    }
  `,
  updateUserPhoneBook: gql`
    mutation updateUserPhoneBook($id: ID!, $data: UpdateUserPhoneBookInput!) {
      updateUserPhoneBook(id: $id, data: $data) {
        id
      }
    }
  `,
  getAllCompanyPhoneBook: gql`
    query getAllCompanyPhoneBook($page: Int, $limit: Int) {
      getAllCompanyPhoneBook(
        q: { page: $page, limit: $limit, order: { createdAt: -1 } }
      ) {
        data {
          id
          name
          email
          phone
          description
          connectionCout
          address
          typeCompanyPhoneBookId
          typeCompanyPhoneBook {
            id
            name
            type
          }
          userId
          user {
            id
            name
          }
          companyId
          company {
            id
            name
          }
        }
      }
    }
  `,
  createCompanyPhoneBook: gql`
    mutation createCompanyPhoneBook($data: CreateCompanyPhoneBookInput!) {
      createCompanyPhoneBook(data: $data) {
        id
      }
    }
  `,
  updateCompanyPhoneBook: gql`
    mutation updateCompanyPhoneBook(
      $id: ID!
      $data: UpdateCompanyPhoneBookInput!
    ) {
      updateCompanyPhoneBook(id: $id, data: $data) {
        id
      }
    }
  `,
  getAllCompanyPhoneBookWOPaging: gql`
    query getAllCompanyPhoneBookWOPaging {
      getAllCompanyPhoneBookWOPaging {
        id
        name
      }
    }
  `,
  getTypeCompany: gql`
    query getTypeCompany {
      getTypeCompany {
        id
        name
      }
    }
  `,
};

export default action;
