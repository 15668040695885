import React from "react";

export const newRoutes = [
  {
    exact: true,
    key: "new",
    path: `/new-list`,
    component: React.lazy(() => import("../../pages/new-list")),
  },
];
