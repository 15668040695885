import { ApolloProvider } from "@apollo/client";
import { LocaleProvider } from "@douyinfe/semi-ui";
import vi_VN from "@douyinfe/semi-ui/lib/es/locale/source/vi_VN";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import AuthTokenLogin from "./component/common/authToken/authTokenLogin";
import client from "./config/apolloClient";
import riverGroupClient from "./config/apolloClient.riverGroup";
import riverHospitalClient from "./config/apolloClient.riverHospitality";
import "./index.css";
import "./responsive.css";
import SignInPage from "./pages/auth/signInPage";
import moment from "moment";
import history from "./history.js";
import MainLayout from "./component/layout/mainLayout";
import { useContext } from "react";

export const BaseURIContext = React.createContext(null);
export const UserDataContext = React.createContext(null);
export const ThemeModeContext = React.createContext(null);

function App() {
  // state
  const [dataName, setDataName] = useState(null);
  const [baseClient, setBaseClient] = useState(client);
  const [userData, setUserData] = useState({ data: {}, isLogin: false });
  const [themeMode, setThemeMode] = useState(() => {
    return { mode: localStorage.getItem("semi-theme") };
  });

  let storageTimestamp;

  // function
  function doSetBaseClient(data) {
    switch (data) {
      case "riverLand":
        setBaseClient(client);
        setDataName(data);
        break;
      case "riverGroup":
        setDataName(riverGroupClient);
        setDataName(data);
        break;
      case "riverMaker":
        setBaseClient(client);
        setDataName(data);
        break;
      case "riverStream":
        setBaseClient(client);
        setDataName(data);
        break;
      case "riverHospitality":
        setBaseClient(riverHospitalClient);
        setDataName(data);
        break;
      default:
        setBaseClient(client);
        break;
    }
  }

  const userAuthInfo = useSelector((state) => state.auth.user);

  function setUserDataContext(payload) {
    setUserData({ ...payload });
  }

  const setThemeModeContext = (payload) => {
    setThemeMode({ ...payload });
  };

  useEffect(() => {
    if (userAuthInfo) {
      setUserDataContext({ data: { ...userAuthInfo }, isLogin: true });
    } else {
      setUserDataContext({ data: {}, isLogin: false });
    }
  }, [userAuthInfo]);

  useEffect(() => {
    if (dataName !== null) {
      setBaseClient(client);
    }
  }, []);

  if (typeof window !== "undefined") {
    storageTimestamp = JSON.parse(localStorage.getItem("crmTimestamp"));
  }

  //Auto clear local storage after 24 hours
  const expiredTime = moment(storageTimestamp).add(24, "h").toDate();
  const currentTime = new Date(Date.now());

  useEffect(() => {
    if (expiredTime < currentTime) {
      localStorage.clear();
      window.location.reload();
    }
  }, []);

  return (
    <BaseURIContext.Provider
      value={{ baseClient: baseClient, doSetBaseClient }}
    >
      <UserDataContext.Provider value={{ userData, setUserDataContext }}>
        <ThemeModeContext.Provider value={{ themeMode, setThemeModeContext }}>
          <LocaleProvider locale={vi_VN}>
            <ApolloProvider client={baseClient}>
              <div className="App">
                <Router history={history}>
                  <Switch>
                    <Route path="/sign-in" component={SignInPage} />
                    <AuthTokenLogin>
                      <MainLayout />
                    </AuthTokenLogin>
                    <Route
                      path="*"
                      component={() => <div>Page Not Found</div>}
                    />
                  </Switch>
                </Router>
              </div>
            </ApolloProvider>
          </LocaleProvider>
        </ThemeModeContext.Provider>
      </UserDataContext.Provider>
    </BaseURIContext.Provider>
  );
}

export const useTheme = () => useContext(ThemeModeContext);

export default App;
