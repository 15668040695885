import { gql } from "@apollo/client";

const action = {
  getAllCompany: gql`
    query getAllCompany($page: Int, $limit: Int, $name: String) {
      getAllCompany(
        q: {
          page: $page
          limit: $limit
          search: $name
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          name
          image
          address
          email
          phone
          description
          typeCompanyPhoneBook {
            name
          }
          typeCompanyPhoneBookId
          createdAt
          updatedAt
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,
  createCompany: gql`
    mutation createCompany($data: CreateCompanyInput!) {
      createCompany(data: $data) {
        id
      }
    }
  `,
  updateCompany: gql`
    mutation updateCompany($id: ID!, $data: UpdateCompanyInput!) {
      updateCompany(id: $id, data: $data) {
        id
      }
    }
  `,

  getOneCompany: gql`
    query getOneCompany($id: ID!) {
      getOneCompany(id: $id) {
        name
        address
        image
        email
        phone
        description
        typeCompanyPhoneBookId
      }
    }
  `,

  deleteOneCompany: gql`
    mutation deleteOneCompany($id: ID!) {
      deleteOneCompany(id: $id) {
        id
      }
    }
  `,

  getAllCompanyWOPaging: gql`
    query {
      getAllCompanyWOPaging {
        id
        name
        image
      }
    }
  `,
};

export default action;
