import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./auth/authStore";
import LoadingReducer from "./loading/loadingStore";
import addressReducer from './address/index';

export const store = configureStore({
  reducer: {
    // auth
    auth: AuthReducer,
    loading: LoadingReducer,
    address: addressReducer,
  },
  devtools: true,
});

export default store;
