import { gql } from "@apollo/client";

const action = {
  getAllNewsCategory: gql`
    query getAllNewsCategory($page: Int, $limit: Int) {
      getAllNewsCategory(
        q: { page: $page, limit: $limit, order: { createdAt: -1 } }
      ) {
        data {
          id
          name
          tagName
          rgbItemColor
          rgbCardColor
          createdAt
          updatedAt
        }
        total
      }
    }
  `,
  createNewsCategory: gql`
    mutation createNewsCategory($data: CreateNewsCategoryInput!) {
      createNewsCategory(data: $data) {
        id
      }
    }
  `,
  updateNewsCategory: gql`
    mutation updateNewsCategory($id: ID!, $data: UpdateNewsCategoryInput!) {
      updateNewsCategory(id: $id, data: $data) {
        id
      }
    }
  `,
};

export default action;
