import { gql } from "@apollo/client";

const action = {
  getAllRhUser: gql`
    query getAllRhUser($page: Int, $limit: Int, $filter: Mixed) {
      getAllRhUser(
        q: {
          limit: $limit
          page: $page
          order: { createdAt: -1 }
          filter: $filter
        }
      ) {
        data {
          id
          name
          phone
          role
          email
          avatar
          userCode
          department {
            name
          }
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,
  getOneUser: gql`
    query getOneUser($id: ID!) {
      getOneUser(id: $id) {
        departmentIds
        email
        id
        name
        username
        phone
        address
        avatar
        userCode
        departments {
          name
          id
        }
        role
        hosPermission {
          userId
          position
          listStaff {
            write
            edit
            delete
          }
          hotelProject {
            write
            edit
            delete
          }
          campProject {
            write
            edit
            delete
          }
          settingProject {
            write
            edit
            delete
          }
          hotelSales {
            approve
            reject
          }
          campSales {
            approve
            reject
          }
          newsMarketing {
            write
            edit
            delete
          }
          authorization
          isCeo
          isManagerStaff
          isManagerSales
          isManagerProject
          isManagerMaketing
        }
      }
    }
  `,
};

export default action;
