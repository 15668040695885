import { gql } from "@apollo/client";

const action = {
  // Camping location
  getAllRhCampingLocation: gql`
    query getAllRhCampingLocation(
      $page: Int
      $limit: Int
      $name: String
      $filter: Mixed
    ) {
      getAllRhCampingLocation(
        q: {
          limit: $limit
          page: $page
          search: $name
          filter: $filter
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          name
          address
          campingLocationCode
          checkinTime
          checkoutTime
          images
          tentCount
          tents {
            id
            name
          }
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,

  getOneRhCampingLocation: gql`
    query getOneRhCampingLocation($id: ID!) {
      getOneRhCampingLocation(id: $id) {
        id
        name
        images
        address
        description
        checkinTime
        checkoutTime
        campingLocationCode
        provinceId
        districtId
        wardId
        street
        tents {
          id
          name
        }
        location {
          coordinates
        }
        tentCount
      }
    }
  `,

  createRhCampingLocation: gql`
    mutation createRhCampingLocation($data: CreateRhCampingLocationInput!) {
      createRhCampingLocation(data: $data) {
        id
      }
    }
  `,

  updateRhCampingLocation: gql`
    mutation updateRhCampingLocation(
      $id: ID!
      $data: UpdateRhCampingLocationInput!
    ) {
      updateRhCampingLocation(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOneRhCampingLocation: gql`
    mutation deleteOneRhCampingLocation($id: ID!) {
      deleteOneRhCampingLocation(id: $id) {
        id
      }
    }
  `,

  // Tent
  getAllRhTent: gql`
    query getAllRhTent($page: Int, $limit: Int, $name: String, $filter: Mixed) {
      getAllRhTent(
        q: {
          limit: $limit
          page: $page
          search: $name
          filter: $filter
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          name
          tentCode
          images
          location {
            coordinates
          }
          amountOfPeople
          address
          area
          price
          campingLocation {
            id
            name
          }
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,

  getOneRhTent: gql`
    query getOneRhTent($id: ID!) {
      getOneRhTent(id: $id) {
        id
        name
        location {
          coordinates
        }
        address
        area
        images
        price
        description
        price
        amountOfPeople
        lightsInfo
        drinkInfo
        socketInfo
        roomUtilityIds
        roomUtilities {
          name
        }
        campingLocation {
          id
          name
        }
      }
    }
  `,

  createRhTent: gql`
    mutation createRhTent($data: CreateRhTentInput!) {
      createRhTent(data: $data) {
        id
      }
    }
  `,

  deleteOneRhTent: gql`
    mutation deleteOneRhTent($id: ID!) {
      deleteOneRhTent(id: $id) {
        id
      }
    }
  `,
  updateRhTent: gql`
    mutation updateRhTent($id: ID!, $data: UpdateRhTentInput!) {
      updateRhTent(id: $id, data: $data) {
        id
      }
    }
  `,

  // Tent book info

  getAllTentBookInfo: gql`
    query getAllTentBookInfo(
      $page: Int
      $limit: Int
      $filter: Mixed
      $name: String
    ) {
      getAllTentBookInfo(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: { createdAt: -1 }
          search: $name
        }
      ) {
        data {
          id
          tentCode
          bookingCode
          price
          userBooking {
            name
            phone
          }
          paymentMethods
          isPaid
          bookDates
          status
          tent {
            name
          }
          campingLocation {
            id
            name
          }
          status
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,

  getOneTentBookInfo: gql`
    query getOneTentBookInfo($id: ID!) {
      getOneTentBookInfo(id: $id) {
        name
        tent {
          name
          address
          images
          location {
            coordinates
          }
        }
        price
        paymentMethods
        status
        userBooking {
          name
          phone
        }
      }
    }
  `,

  acceptTentBook: gql`
    mutation acceptTentBook($id: ID!) {
      acceptTentBook(tentBookInfoId: $id) {
        id
      }
    }
  `,
  declineTentBook: gql`
    mutation declineTentBook($id: ID!) {
      declineTentBook(tentBookInfoId: $id) {
        id
      }
    }
  `,

  getAllRhTentBookedLog: gql`
    query getAllRhTentBookedLog($page: Int, $limit: Int, $filter: Mixed) {
      getAllRhTentBookedLog(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          action
          updatedAt
          tentBookInfo {
            id
            price
            name
          }
          userConfirm {
            name
            phone
          }
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,
};

export default action;
