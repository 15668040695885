import { gql } from "@apollo/client";

const action = {
  getAllRhStaff: gql`
    query getAllRhStaff($page: Int, $limit: Int, $filter: Mixed) {
      getAllRhStaff(
        q: {
          limit: $limit
          page: $page
          order: { createdAt: -1 }
          filter: $filter
        }
      ) {
        data {
          id
          name
          phone
          role
          email
          avatar
          userCode
          departments {
            id
            name
          }
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,

  getAllDepartmentWoPaging: gql`
    query {
      getAllDepartmentWoPaging {
        name
        id
      }
    }
  `,

  updateStaff: gql`
    mutation updateStaff($id: ID!, $data: UpdateStaffInput!) {
      updateStaff(id: $id, data: $data) {
        id
      }
    }
  `,

  rhUpdateStaff: gql`
    mutation rhUpdateStaff($userId: ID!, $data: updateStaffDataInput!) {
      rhUpdateStaff(userId: $userId, data: $data) {
        id
      }
    }
  `,

  rhRegisterStaff: gql`
    mutation rhRegisterStaff(
      $email: String!
      $phone: String!
      $password: String!
      $name: String!
      $avatar: String
      $departmentIds: [ID]!
    ) {
      rhRegisterStaff(
        email: $email
        phone: $phone
        password: $password
        name: $name
        avatar: $avatar
        departmentIds: $departmentIds
      ) {
        username
      }
    }
  `,
};

export default action;
