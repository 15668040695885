import { gql } from "@apollo/client";

const action = {
  getAllRvGroupProject: gql`
    query GetAllRvGroupProject($page: Int, $limit: Int) {
      getAllRvGroupProject(
        q: { page: $page, limit: $limit, order: { createdAt: -1 } }
      ) {
        data {
          id
          icName
          status
          title
          description
          timeLine
          progressPercent
          realEstateProject {
            category {
              name
            }
            images
            title
            overview
            location
            pricePerm2
            address
          }
        }
        total
      }
    }
  `,
  updateRvGroupProject: gql`
    mutation UpdateRvGroupProject($id: ID!, $data: UpdateRvGroupProjectInput!) {
      updateRvGroupProject(id: $id, data: $data) {
        id
      }
    }
  `,
  createRvGroupProject: gql`
    mutation CreateRvGroupProjectInput($data: CreateRvGroupProjectInput!) {
      createRvGroupProject(data: $data) {
        id
      }
    }
  `,
};

export default action;
