import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB2hZy33Zw13w8sB4MLyuiDtncr1QHlIes",
  authDomain: "land-map-74d41.firebaseapp.com",
  projectId: "land-map-74d41",
  storageBucket: "land-map-74d41.appspot.com",
  messagingSenderId: "858951064884",
  appId: "1:858951064884:web:a46a96b446ea66af265961",
  measurementId: "G-LFYHPHE0BK",
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage();
