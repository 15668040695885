import { gql } from "@apollo/client";

const action = {
  getAllDocument: gql`
    query getAllDocument($limit: Int, $page: Int) {
      getAllDocument(
        q: { limit: $limit, page: $page, order: { createdAt: -1 } }
      ) {
        data {
          id
          name
          link
          type
          size
          createdAt
        }
        total
      }
    }
  `,
  createDocument: gql`
    mutation createDocument($data: CreateDocumentInput!) {
      createDocument(data: $data) {
        id
      }
    }
  `,
  deleteOneDocument: gql`
    mutation deleteOneDocument($id: ID!) {
      deleteOneDocument(id: $id) {
        id
      }
    }
  `,
};

export default action;
