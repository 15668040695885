import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { getStoreAuthToken } from "../redux/auth/authStore";

const withMainLayout = (WrappedComponent) => {
  return ({
    component: Component,
    permissionKey,
    permissionSubKey,
    ...props
  }) => {
    const checkAuthTokenHasError = useSelector((state) => state.auth.hasError);
    const { user } = useSelector((state) => state.auth);

    let permission = {};
    if (permissionKey && permissionSubKey && user) {
      permission = user[permissionKey][permissionSubKey];
    }

    const Routecontent = (
      <Route
        {...props}
        render={(routeProps) => {
          return (
            <WrappedComponent {...routeProps}>
              <Component {...routeProps} {...permission} />
            </WrappedComponent>
          );
        }}
      />
    );
    const authToken = getStoreAuthToken();

    if (checkAuthTokenHasError || !authToken) {
      return <Redirect to={"/sign-in"} />;
    }

    return Routecontent;
  };
};

export default withMainLayout;
