import { gql } from "@apollo/client";
const action = {
  getAllRvsVideo: gql`
    query GetAllRvsVideo($name: String, $page: Int, $limit: Int) {
      getAllRvsVideo(
        q: {
          search: $name
          page: $page
          limit: $limit
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          name
          hashtag
          ytubUrl
          description
          like
          dislike
          userCreated {
            name
          }
          view
          popular
          sponsor
          createdAt
        }
        total
      }
    }
  `,
};

export default action;
