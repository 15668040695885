import { gql } from "@apollo/client";

const action = {
  getAllInterested: gql`
    query GetAllInterested(
      $name: String
      $page: Int
      $limit: Int
      $filter: Mixed
    ) {
      getAllInterested(
        q: {
          search: $name
          page: $page
          limit: $limit
          filter: $filter
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          rvsStreamId
          name
          phone
          userId
          type
          consulted
        }
        total
      }
    }
  `,
  updateInterested: gql`
    mutation UpdateInterested($id: ID!, $consulted: Boolean) {
      updateInterested(id: $id, data: { consulted: $consulted }) {
        id
      }
    }
  `,
};

export default action;
