import { gql } from "@apollo/client";

const action = {
  getAllRvStreamProject: gql`
    query GetAllRvStreamProject($name: String, $page: Int, $limit: Int) {
      getAllRvStreamProject(
        q: {
          search: $name
          page: $page
          limit: $limit
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          title
          projectTagIds
          projectIconId
          projectIcon {
            image
            key
          }
          projectTags {
            name
            rgbItemColor
          }
        }
      }
    }
  `,
  updateRvStreamProject: gql`
    mutation UpdateRVStreamProject(
      $id: ID!
      $data: UpdateRvStreamProjectInput!
    ) {
      updateRvStreamProject(id: $id, data: $data) {
        id
      }
    }
  `,
  createRvStreamProject: gql`
    mutation CreateRvMakerProject($data: CreateRvStreamProjectInput!) {
      createRvStreamProject(data: $data) {
        id
      }
    }
  `,
};

export default action;
