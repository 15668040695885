import React from "react";

export const paymentRoutes = [
  {
    exact: true,
    key: "payment",
    path: `/payment-list`,
    component: React.lazy(() => import("../../pages/payment-list")),
  },
];
