import { gql } from "@apollo/client";
const action = {
  loginUser: gql`
    mutation LoginUser($username: String!, $password: String!) {
      loginUser(username: $username, password: $password) {
        user {
          id
          name
          phone
          role
          avatar
          appRoles
          hosPermission {
            id
            userId
            listStaff {
              write
              edit
              delete
            }
            hotelProject {
              write
              edit
              delete
            }
            campProject {
              write
              edit
              delete
            }
            settingProject {
              write
              edit
              delete
            }
            hotelSales {
              approve
              reject
            }
            campSales {
              approve
              reject
            }
            newsMarketing {
              write
              edit
              delete
            }
            authorization
            position
            isCeo
            isManagerSales
            isManagerStaff
            isManagerProject
            isManagerMaketing
          }
        }
        token
      }
    }
  `,
  getMe: gql`
    query GetMe {
      getMe {
        id
        name
        role
        phone
        avatar
        devMode
        departmentIds
        appRoles
        hosPermission {
          id
          userId
          listStaff {
            write
            edit
            delete
          }
          hotelProject {
            write
            edit
            delete
          }
          campProject {
            write
            edit
            delete
          }
          settingProject {
            write
            edit
            delete
          }
          hotelSales {
            approve
            reject
          }
          campSales {
            approve
            reject
          }
          newsMarketing {
            write
            edit
            delete
          }
          authorization
          position
          isCeo
          isManagerStaff
          isManagerSales
          isManagerProject
          isManagerMaketing
        }
      }
    }
  `,
};

export default action;
