const LandMarkerDartLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={258} height={64} viewBox="0 0 258 64" fill="none">
            <path className="logo__icon-wrapper" d="M24.9937 0C11.1904 0 0 11.1912 0 24.9957C0 39.7522 13.0126 54.5087 20.2585 61.5825C22.892 64.1525 27.0955 64.1525 29.7266 61.5825C36.9748 54.5087 49.9874 39.7522 49.9874 24.9957C49.9874 11.1912 38.7971 0 24.9937 0ZM24.9937 38.6297C17.4638 38.6297 11.3608 32.5262 11.3608 24.9957C11.3608 17.4651 17.4638 11.3617 24.9937 11.3617C32.5236 11.3617 38.6267 17.4651 38.6267 24.9957C38.6267 32.5262 32.5236 38.6297 24.9937 38.6297ZM36.3545 24.9957C36.3545 31.2605 31.2581 36.3573 24.9937 36.3573C18.7294 36.3573 13.6329 31.2605 13.6329 24.9957C13.6329 18.7308 18.7294 13.634 24.9937 13.634C31.2581 13.634 36.3545 18.7308 36.3545 24.9957Z" fill="url(#paint0_linear_2_71)" />
            <path className="logo__icon" d="M24.9895 7.776C15.2505 7.776 7.35509 15.6701 7.35509 25.4074C7.35509 35.8164 16.5362 46.2254 21.6486 51.2151C23.5066 53.0279 26.4724 53.0279 28.3288 51.2151C33.4428 46.2254 42.6239 35.8164 42.6239 25.4074C42.6239 15.6701 34.7285 7.776 24.9895 7.776ZM24.9895 35.0246C19.6767 35.0246 15.3707 30.7193 15.3707 25.4074C15.3707 20.0956 19.6767 15.7903 24.9895 15.7903C30.3022 15.7903 34.6082 20.0956 34.6082 25.4074C34.6082 30.7193 30.3022 35.0246 24.9895 35.0246ZM33.0051 25.4074C33.0051 29.8265 29.4093 33.4217 24.9895 33.4217C20.5697 33.4217 16.9738 29.8265 16.9738 25.4074C16.9738 20.9884 20.5697 17.3931 24.9895 17.3931C29.4093 17.3931 33.0051 20.9884 33.0051 25.4074Z" fill="white" />
            <ellipse cx="25.0242" cy="24.9214" rx="17.1441" ry="17.1454" fill="white" />
            <path className="logo__icon" d="M32.449 21.4661C33.9722 23.1947 34.7133 25.183 34.7085 27.4576C34.7016 30.7884 34.7064 34.1193 34.7062 37.4502C34.7062 37.6078 34.7062 37.7654 34.7062 37.9603C33.5898 37.9603 32.4932 37.9603 31.3479 37.9603C31.3479 37.7807 31.348 37.6115 31.3479 37.4424C31.3475 34.0394 31.3527 30.6365 31.3439 27.2335C31.34 25.7088 30.7127 24.4469 29.6467 23.3858C29.5788 23.3182 29.4934 23.2682 29.3553 23.1641C29.3553 28.1318 29.3553 33.036 29.3553 37.9687C28.2212 37.9687 27.1343 37.9687 25.9949 37.9687C25.9949 32.6236 25.9949 27.2832 25.9949 21.917C25.3161 21.917 24.6891 21.917 24.0157 21.917C24.0157 25.4482 24.0157 28.9734 24.0157 32.6496C23.5257 32.488 23.0879 32.4109 22.7178 32.2094C21.4308 31.5084 20.8232 30.3437 20.7076 28.9299C20.6349 28.0414 20.6755 27.1432 20.6744 26.2494C20.6716 23.8558 20.6738 21.4622 20.674 19.0686C20.674 18.9115 20.674 18.7545 20.674 18.532C20.8424 18.532 20.9804 18.532 21.1183 18.532C22.6746 18.532 24.2309 18.5245 25.787 18.5337C28.4161 18.5493 30.6371 19.5076 32.449 21.4661Z" fill="url(#paint1_linear_2_71)" />
            <path className="logo__icon" d="M15.2703 29.0755C15.2704 25.5497 15.2704 22.0663 15.2704 18.5641C16.4083 18.5641 17.4984 18.5641 18.634 18.5641C18.634 18.74 18.634 18.8941 18.634 19.0481C18.634 22.3483 18.6789 25.6491 18.6212 28.9483C18.5647 32.1808 21.0782 34.5854 23.8751 34.6437C23.9023 34.6442 23.9291 34.6582 23.9911 34.6759C23.9911 35.764 23.9911 36.8619 23.9906 37.9599C20.255 38.1609 15.2822 34.8885 15.2703 29.0755Z" fill="url(#paint2_linear_2_71)" />
            <path className="logo__name" d="M58.5816 17.3584H63.6115V43.641H58.5816V17.3584Z" fill="url(#paint3_linear_2_71)" />
            <path className="logo__name" d="M67.5187 37.9755C67.5187 35.83 68.0664 34.3773 69.1618 33.6174C70.2572 32.8575 72.0121 32.4776 74.4264 32.4776C76.0583 32.4776 77.679 32.6452 79.2886 32.9805V32.377C79.2886 31.2372 78.9197 30.4327 78.182 29.9633C77.4666 29.4716 76.3377 29.2258 74.7952 29.2258C73.0963 29.2258 71.1626 29.5163 68.9942 30.0974V26.5439C71.4308 25.784 73.7222 25.4041 75.8683 25.4041C77.5896 25.4041 79.0203 25.5605 80.1604 25.8734C81.3005 26.164 82.1612 26.6333 82.7424 27.2814C83.346 27.9072 83.7595 28.6112 83.9831 29.3934C84.2066 30.1533 84.3184 31.1031 84.3184 32.2429V43.641H80.1269L79.6909 42.4677C77.9249 43.4734 76.0806 43.9763 74.1581 43.9763C71.7662 43.9763 70.056 43.5293 69.0277 42.6353C68.0217 41.719 67.5187 40.1657 67.5187 37.9755ZM75.8012 35.9306C74.7505 35.9306 73.9458 36.087 73.3869 36.3999C72.828 36.6904 72.5486 37.238 72.5486 38.0426C72.5486 38.646 72.5933 39.1042 72.6827 39.417C72.7721 39.7299 73.0292 39.9981 73.454 40.2216C73.8787 40.4451 74.5158 40.5569 75.3653 40.5569C76.9078 40.5569 78.2155 40.244 79.2886 39.6182V36.2993C78.1038 36.0535 76.9413 35.9306 75.8012 35.9306Z" fill="url(#paint4_linear_2_71)" />
            <path className="logo__name" d="M89.0684 43.641V25.7393H93.2935L93.6959 26.9797C95.9984 25.9293 98.2116 25.4041 100.335 25.4041C104.694 25.4041 106.874 27.9184 106.874 32.9469V43.641H101.811V34.422C101.788 32.3882 101.531 31.0361 101.039 30.3656C100.57 29.6951 99.7093 29.3599 98.4575 29.3599C97.2056 29.3599 95.7525 29.7622 94.0983 30.5667V43.641H89.0684Z" fill="url(#paint5_linear_2_71)" />
            <path className="logo__name" d="M118.371 43.9763C117.052 43.9763 115.923 43.8422 114.984 43.574C114.045 43.3058 113.184 42.8477 112.402 42.1995C111.642 41.529 111.072 40.5792 110.692 39.35C110.312 38.0984 110.122 36.5563 110.122 34.7237C110.122 32.8464 110.312 31.2819 110.692 30.0304C111.072 28.7788 111.653 27.829 112.435 27.1808C113.218 26.5104 114.067 26.0522 114.984 25.8064C115.923 25.5382 117.052 25.4041 118.371 25.4041C120.36 25.4041 122.182 25.8511 123.836 26.745V17.3584H128.866V43.641H124.876L124.406 42.0654C122.931 43.3393 120.919 43.9763 118.371 43.9763ZM122.931 30.5667C122.305 29.7398 121.187 29.3264 119.578 29.3264C117.968 29.3264 116.828 29.6728 116.158 30.3656C115.509 31.0584 115.185 32.5111 115.185 34.7237C115.185 36.2435 115.353 37.3944 115.688 38.1767C116.046 38.9589 116.515 39.4729 117.096 39.7188C117.678 39.9423 118.505 40.054 119.578 40.054C121.12 40.054 122.216 39.6294 122.864 38.7801C123.535 37.9308 123.87 36.5787 123.87 34.7237C123.87 32.7793 123.557 31.3937 122.931 30.5667Z" fill="url(#paint6_linear_2_71)" />
            <path className="logo__name" d="M134.216 25.7393H138.205L138.675 26.9462C140.82 25.9181 143.021 25.4041 145.279 25.4041C147.357 25.4041 148.921 26.0522 149.972 27.3485C152.609 26.0522 155.034 25.4041 157.246 25.4041C159.101 25.4041 160.543 25.974 161.571 27.1138C162.621 28.2313 163.157 30.0862 163.18 32.6788V43.641H158.118V34.489C158.118 31.0696 157.034 29.3599 154.866 29.3599C153.659 29.3599 152.419 29.7063 151.145 30.3991C151.19 30.5779 151.246 31.2484 151.313 32.4106V43.641H146.284V34.489C146.24 32.7681 145.96 31.4831 145.446 30.6338C144.932 29.7845 144.094 29.3599 142.932 29.3599C141.837 29.3599 140.608 29.684 139.245 30.3321V43.641H134.216V25.7393Z" fill="#3E5052" />
            <path className="logo__name" d="M166.427 37.9755C166.427 35.83 166.974 34.3773 168.069 33.6174C169.164 32.8575 170.919 32.4776 173.332 32.4776C174.964 32.4776 176.584 32.6452 178.193 32.9805V32.377C178.193 31.2372 177.824 30.4327 177.087 29.9633C176.372 29.4716 175.243 29.2258 173.701 29.2258C172.003 29.2258 170.069 29.5163 167.902 30.0974V26.5439C170.338 25.784 172.628 25.4041 174.774 25.4041C176.495 25.4041 177.925 25.5605 179.065 25.8734C180.204 26.164 181.065 26.6333 181.646 27.2814C182.249 27.9072 182.663 28.6112 182.886 29.3934C183.11 30.1533 183.222 31.1031 183.222 32.2429V43.641H179.031L178.595 42.4677C176.83 43.4734 174.986 43.9763 173.064 43.9763C170.673 43.9763 168.963 43.5293 167.935 42.6353C166.93 41.719 166.427 40.1657 166.427 37.9755ZM174.707 35.9306C173.656 35.9306 172.852 36.087 172.293 36.3999C171.734 36.6904 171.455 37.238 171.455 38.0426C171.455 38.646 171.5 39.1042 171.589 39.417C171.679 39.7299 171.936 39.9981 172.36 40.2216C172.785 40.4451 173.422 40.5569 174.271 40.5569C175.813 40.5569 177.12 40.244 178.193 39.6182V36.2993C177.009 36.0535 175.847 35.9306 174.707 35.9306Z" fill="#3E5052" />
            <path className="logo__name" d="M187.97 43.641V25.7393H191.993L192.529 27.1138C194.339 25.974 196.496 25.4041 198.999 25.4041C199.826 25.4041 200.418 25.4153 200.776 25.4376V29.3934C200.217 29.3264 199.625 29.2928 198.999 29.2928C197.211 29.2928 195.211 29.9074 192.999 31.1367V43.641H187.97Z" fill="#3E5052" />
            <path className="logo__name" d="M203.684 17.3584H208.712V31.8407H210.69L215.752 25.7393H221.92L215.249 33.785L222.758 43.641H216.758L210.724 35.6959H208.712V43.641H203.684V17.3584Z" fill="#3E5052" />
            <path className="logo__name" d="M233.283 43.9763C229.953 43.9763 227.439 43.2611 225.74 41.8308C224.042 40.4004 223.193 38.009 223.193 34.6567C223.193 32.7793 223.394 31.2261 223.796 29.9968C224.198 28.7676 224.813 27.829 225.64 27.1808C226.489 26.5104 227.45 26.0522 228.523 25.8064C229.595 25.5382 230.914 25.4041 232.478 25.4041C235.562 25.4041 237.797 25.9628 239.183 27.0803C240.568 28.1754 241.261 30.0192 241.261 32.6117V36.3329H228.355C228.534 37.6962 229.014 38.6795 229.796 39.2829C230.579 39.864 231.841 40.1546 233.585 40.1546C235.238 40.1546 237.551 39.864 240.524 39.2829V42.87C238.199 43.6075 235.786 43.9763 233.283 43.9763ZM232.478 29.2928C230.981 29.2928 229.931 29.5722 229.327 30.1309C228.746 30.6673 228.4 31.6395 228.288 33.0475H236.199V32.6117C236.199 31.4719 235.909 30.6338 235.328 30.0974C234.747 29.561 233.797 29.2928 232.478 29.2928Z" fill="#3E5052" />
            <path className="logo__name" d="M245.194 43.641V25.7393H249.217L249.753 27.1138C251.564 25.974 253.72 25.4041 256.223 25.4041C257.05 25.4041 257.642 25.4153 258 25.4376V29.3934C257.441 29.3264 256.849 29.2928 256.223 29.2928C254.435 29.2928 252.435 29.9074 250.223 31.1367V43.641H245.194Z" fill="#3E5052" />
            <path className="logo__name" d="M61.0317 60.2576C60.6893 60.2576 60.3963 60.2222 60.1527 60.1516C59.909 60.081 59.6856 59.9603 59.4826 59.7895C59.2853 59.6129 59.1373 59.3627 59.0387 59.0389C58.9401 58.7093 58.8908 58.3031 58.8908 57.8203C58.8908 57.3258 58.9401 56.9137 59.0387 56.584C59.1373 56.2544 59.2882 56.0042 59.4913 55.8334C59.6943 55.6568 59.9148 55.5361 60.1527 55.4714C60.3963 55.4007 60.6893 55.3654 61.0317 55.3654C61.548 55.3654 62.0209 55.4832 62.4502 55.7186V53.2461H63.7556V60.1693H62.72L62.5982 59.7542C62.2152 60.0898 61.6931 60.2576 61.0317 60.2576ZM62.2152 56.7253C62.0528 56.5075 61.7627 56.3986 61.345 56.3986C60.9272 56.3986 60.6313 56.4899 60.4573 56.6723C60.289 56.8548 60.2049 57.2375 60.2049 57.8203C60.2049 58.2206 60.2484 58.5238 60.3354 58.7299C60.4283 58.9359 60.5501 59.0713 60.7009 59.1361C60.8518 59.195 61.0665 59.2244 61.345 59.2244C61.7453 59.2244 62.0296 59.1125 62.1978 58.8888C62.3719 58.6651 62.4589 58.309 62.4589 57.8203C62.4589 57.3082 62.3777 56.9432 62.2152 56.7253Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M65.1636 60.1693V55.4537H66.469V60.1693H65.1636ZM65.1375 54.4912V53.3874H66.4951V54.4912H65.1375Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M67.3525 56.8666C67.3525 55.8658 68.0517 55.3654 69.4499 55.3654C70.1055 55.3654 70.7872 55.4655 71.4951 55.6657V56.7165C70.7814 56.4987 70.0678 56.3898 69.3542 56.3898C69.1163 56.3898 68.9394 56.4221 68.8233 56.4869C68.7131 56.5458 68.658 56.6635 68.658 56.8401C68.658 56.9814 68.6986 57.0844 68.7798 57.1492C68.8668 57.214 69.0554 57.2463 69.3455 57.2463C69.6414 57.2463 69.8938 57.2552 70.1026 57.2728C70.3173 57.2905 70.5349 57.3288 70.7553 57.3876C70.9816 57.4406 71.1644 57.5171 71.3036 57.6172C71.4429 57.7173 71.556 57.8556 71.643 58.0323C71.7301 58.2089 71.7736 58.4208 71.7736 58.6681C71.7736 58.9918 71.7213 59.2627 71.6169 59.4805C71.5125 59.6983 71.3558 59.8631 71.147 59.975C70.9381 60.081 70.7118 60.1545 70.4681 60.1958C70.2245 60.237 69.9315 60.2576 69.5892 60.2576C68.7827 60.2576 68.072 60.1398 67.457 59.9043V58.88C68.13 59.1155 68.8697 59.2332 69.6762 59.2332C69.9779 59.2332 70.1839 59.195 70.2941 59.1184C70.4101 59.0419 70.4681 58.9094 70.4681 58.721C70.4681 58.5444 70.3927 58.4296 70.2419 58.3767C70.0968 58.3178 69.8531 58.2883 69.5108 58.2883C68.7334 58.2883 68.1793 58.1883 67.8486 57.9881C67.5179 57.7821 67.3525 57.4082 67.3525 56.8666Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M72.2679 57.8115C72.2679 57.3229 72.3259 56.9137 72.442 56.584C72.5638 56.2544 72.7408 56.0042 72.9729 55.8334C73.2107 55.6568 73.4689 55.5361 73.7474 55.4714C74.0259 55.4007 74.3595 55.3654 74.7482 55.3654C75.34 55.3654 75.9202 55.4508 76.4888 55.6215V56.5929C76.0188 56.4575 75.5634 56.3898 75.1224 56.3898C74.5597 56.3898 74.1593 56.4869 73.9215 56.6812C73.6894 56.8696 73.5733 57.2463 73.5733 57.8115C73.5733 58.3708 73.6894 58.7475 73.9215 58.9418C74.1593 59.1361 74.5597 59.2332 75.1224 59.2332C75.575 59.2332 76.0449 59.1331 76.5323 58.933V59.9573C76.3292 60.0574 76.074 60.131 75.7665 60.1781C75.4648 60.2311 75.2153 60.2576 75.018 60.2576C74.5771 60.2576 74.1999 60.2252 73.8866 60.1604C73.5733 60.0898 73.2891 59.9691 73.0338 59.7984C72.7785 59.6218 72.587 59.3716 72.4594 59.0478C72.3317 58.7181 72.2679 58.306 72.2679 57.8115Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M80.5576 60.1516C80.2791 60.2222 79.9397 60.2576 79.5393 60.2576C79.139 60.2576 78.7996 60.2222 78.5211 60.1516C78.2484 60.081 78.0047 59.9573 77.7901 59.7807C77.5754 59.6041 77.4158 59.3539 77.3114 59.0301C77.207 58.7004 77.1548 58.2942 77.1548 57.8115C77.1548 57.3229 77.207 56.9167 77.3114 56.5929C77.4158 56.2632 77.5754 56.013 77.7901 55.8423C78.0047 55.6657 78.2484 55.542 78.5211 55.4714C78.7996 55.4007 79.139 55.3654 79.5393 55.3654C79.9397 55.3654 80.2762 55.4007 80.5489 55.4714C80.8273 55.542 81.0739 55.6657 81.2886 55.8423C81.5033 56.013 81.6628 56.2632 81.7672 56.5929C81.8717 56.9167 81.9239 57.3229 81.9239 57.8115C81.9239 58.2942 81.8717 58.7004 81.7672 59.0301C81.6628 59.3539 81.5033 59.6041 81.2886 59.7807C81.0739 59.9573 80.8302 60.081 80.5576 60.1516ZM80.3661 56.6812C80.1978 56.4869 79.9222 56.3898 79.5393 56.3898C79.1564 56.3898 78.8808 56.4869 78.7126 56.6812C78.5443 56.8755 78.4602 57.2522 78.4602 57.8115C78.4602 58.3649 78.5443 58.7416 78.7126 58.9418C78.8866 59.1361 79.1622 59.2332 79.5393 59.2332C79.9164 59.2332 80.1891 59.1361 80.3574 58.9418C80.5314 58.7416 80.6185 58.3649 80.6185 57.8115C80.6185 57.2522 80.5343 56.8755 80.3661 56.6812Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M82.2034 55.4537H83.5088L84.7446 59.1449H84.9535L86.1893 55.4537H87.4947L85.8934 60.1693H83.796L82.2034 55.4537Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M90.3893 60.2576C89.5249 60.2576 88.8722 60.0692 88.4312 59.6924C87.9903 59.3156 87.7698 58.6857 87.7698 57.8027C87.7698 57.3082 87.822 56.899 87.9264 56.5752C88.0309 56.2514 88.1904 56.0042 88.4051 55.8334C88.6256 55.6568 88.8751 55.5361 89.1535 55.4714C89.432 55.4007 89.7743 55.3654 90.1805 55.3654C90.9811 55.3654 91.5613 55.5126 91.921 55.807C92.2808 56.0954 92.4606 56.5811 92.4606 57.264V58.2442H89.11C89.1564 58.6033 89.2812 58.8623 89.4843 59.0213C89.6873 59.1743 90.0151 59.2509 90.4677 59.2509C90.897 59.2509 91.4975 59.1743 92.2692 59.0213V59.9662C91.6658 60.1604 91.0392 60.2576 90.3893 60.2576ZM90.1805 56.3898C89.7918 56.3898 89.5191 56.4634 89.3624 56.6105C89.2116 56.7518 89.1216 57.0079 89.0926 57.3788H91.1465V57.264C91.1465 56.9638 91.0711 56.743 90.9202 56.6017C90.7694 56.4604 90.5228 56.3898 90.1805 56.3898Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M93.4817 60.1693V55.4537H94.526L94.6653 55.8158C95.1352 55.5155 95.6951 55.3654 96.3449 55.3654C96.5596 55.3654 96.7133 55.3684 96.8062 55.3743V56.4163C96.6611 56.3986 96.5074 56.3898 96.3449 56.3898C95.8808 56.3898 95.3615 56.5517 94.7871 56.8755V60.1693H93.4817Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M100.927 60.1693L99.0651 55.4537H100.397L101.746 58.986H101.85L103.199 55.4537H104.53L101.902 62.0855H100.579L101.337 60.1693H100.927Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M108.213 60.1516C107.934 60.2222 107.595 60.2576 107.195 60.2576C106.794 60.2576 106.455 60.2222 106.176 60.1516C105.904 60.081 105.66 59.9573 105.445 59.7807C105.231 59.6041 105.071 59.3539 104.967 59.0301C104.862 58.7004 104.81 58.2942 104.81 57.8115C104.81 57.3229 104.862 56.9167 104.967 56.5929C105.071 56.2632 105.231 56.013 105.445 55.8423C105.66 55.6657 105.904 55.542 106.176 55.4714C106.455 55.4007 106.794 55.3654 107.195 55.3654C107.595 55.3654 107.931 55.4007 108.204 55.4714C108.483 55.542 108.729 55.6657 108.944 55.8423C109.158 56.013 109.318 56.2632 109.422 56.5929C109.527 56.9167 109.579 57.3229 109.579 57.8115C109.579 58.2942 109.527 58.7004 109.422 59.0301C109.318 59.3539 109.158 59.6041 108.944 59.7807C108.729 59.9573 108.485 60.081 108.213 60.1516ZM108.021 56.6812C107.853 56.4869 107.577 56.3898 107.195 56.3898C106.812 56.3898 106.536 56.4869 106.368 56.6812C106.2 56.8755 106.115 57.2522 106.115 57.8115C106.115 58.3649 106.2 58.7416 106.368 58.9418C106.542 59.1361 106.817 59.2332 107.195 59.2332C107.572 59.2332 107.844 59.1361 108.013 58.9418C108.187 58.7416 108.274 58.3649 108.274 57.8115C108.274 57.2522 108.19 56.8755 108.021 56.6812Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M112.165 60.2576C111.579 60.2576 111.144 60.1192 110.859 59.8425C110.575 59.56 110.43 59.0831 110.424 58.412V55.4537H111.73V57.9263C111.736 58.462 111.794 58.8123 111.904 58.9771C112.02 59.1361 112.246 59.2156 112.583 59.2156C112.902 59.2156 113.276 59.1125 113.705 58.9065V55.4537H115.011V60.1693H113.914L113.818 59.8602C113.221 60.1251 112.67 60.2576 112.165 60.2576Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M116.42 60.1693V55.4537H117.464L117.604 55.8158C118.074 55.5155 118.633 55.3654 119.283 55.3654C119.498 55.3654 119.652 55.3684 119.745 55.3743V56.4163C119.599 56.3986 119.446 56.3898 119.283 56.3898C118.819 56.3898 118.3 56.5517 117.725 56.8755V60.1693H116.42Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M124.049 62.0855H122.743V55.4537H123.788L123.918 55.8688C124.313 55.5332 124.832 55.3654 125.476 55.3654C125.818 55.3654 126.108 55.4007 126.346 55.4714C126.59 55.542 126.81 55.6657 127.008 55.8423C127.211 56.013 127.361 56.2632 127.46 56.5929C127.559 56.9167 127.608 57.3199 127.608 57.8027C127.608 58.2736 127.553 58.6769 127.443 59.0125C127.332 59.3421 127.176 59.5953 126.973 59.7719C126.77 59.9485 126.546 60.0751 126.303 60.1516C126.065 60.2222 125.789 60.2576 125.476 60.2576C124.896 60.2576 124.42 60.1045 124.049 59.7984V62.0855ZM125.163 56.3986C124.756 56.3986 124.466 56.5134 124.292 56.743C124.124 56.9667 124.04 57.3199 124.04 57.8027C124.04 58.3148 124.121 58.6828 124.284 58.9065C124.446 59.1243 124.739 59.2332 125.163 59.2332C125.574 59.2332 125.867 59.142 126.042 58.9595C126.216 58.7711 126.303 58.3855 126.303 57.8027C126.303 57.5024 126.279 57.2581 126.233 57.0697C126.192 56.8813 126.12 56.7401 126.015 56.6459C125.911 56.5458 125.795 56.481 125.667 56.4516C125.54 56.4163 125.371 56.3986 125.163 56.3986Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M130.852 60.2576C129.988 60.2576 129.335 60.0692 128.894 59.6924C128.453 59.3156 128.233 58.6857 128.233 57.8027C128.233 57.3082 128.285 56.899 128.389 56.5752C128.494 56.2514 128.653 56.0042 128.868 55.8334C129.089 55.6568 129.338 55.5361 129.616 55.4714C129.895 55.4007 130.237 55.3654 130.643 55.3654C131.444 55.3654 132.024 55.5126 132.384 55.807C132.744 56.0954 132.924 56.5811 132.924 57.264V58.2442H129.573C129.619 58.6033 129.744 58.8623 129.947 59.0213C130.15 59.1743 130.478 59.2509 130.931 59.2509C131.36 59.2509 131.96 59.1743 132.732 59.0213V59.9662C132.129 60.1604 131.502 60.2576 130.852 60.2576ZM130.643 56.3898C130.255 56.3898 129.982 56.4634 129.825 56.6105C129.675 56.7518 129.585 57.0079 129.556 57.3788H131.609V57.264C131.609 56.9638 131.534 56.743 131.383 56.6017C131.232 56.4604 130.986 56.3898 130.643 56.3898Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M133.945 60.1693V55.4537H134.989L135.128 55.8158C135.598 55.5155 136.158 55.3654 136.808 55.3654C137.023 55.3654 137.176 55.3684 137.269 55.3743V56.4163C137.124 56.3986 136.97 56.3898 136.808 56.3898C136.344 56.3898 135.824 56.5517 135.25 56.8755V60.1693H133.945Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M137.672 55.4537H138.507V54.968C138.507 54.344 138.658 53.8878 138.96 53.5993C139.262 53.305 139.72 53.1578 140.335 53.1578C140.689 53.1578 141.066 53.2196 141.466 53.3432V54.2793C141.124 54.2086 140.819 54.1733 140.553 54.1733C140.291 54.1733 140.103 54.2351 139.987 54.3587C139.871 54.4824 139.813 54.6855 139.813 54.968V55.4537H141.266V56.4781H139.813V60.1693H138.507V56.4781H137.672V55.4537Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M144.229 60.2576C143.365 60.2576 142.712 60.0692 142.271 59.6924C141.83 59.3156 141.61 58.6857 141.61 57.8027C141.61 57.3082 141.662 56.899 141.767 56.5752C141.871 56.2514 142.031 56.0042 142.245 55.8334C142.466 55.6568 142.715 55.5361 142.994 55.4714C143.272 55.4007 143.614 55.3654 144.021 55.3654C144.821 55.3654 145.401 55.5126 145.761 55.807C146.121 56.0954 146.301 56.5811 146.301 57.264V58.2442H142.95C142.997 58.6033 143.121 58.8623 143.324 59.0213C143.527 59.1743 143.855 59.2509 144.308 59.2509C144.737 59.2509 145.338 59.1743 146.109 59.0213V59.9662C145.506 60.1604 144.879 60.2576 144.229 60.2576ZM144.021 56.3898C143.632 56.3898 143.359 56.4634 143.203 56.6105C143.052 56.7518 142.962 57.0079 142.933 57.3788H144.987V57.264C144.987 56.9638 144.911 56.743 144.76 56.6017C144.609 56.4604 144.363 56.3898 144.021 56.3898Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M146.93 57.8115C146.93 57.3229 146.988 56.9137 147.104 56.584C147.226 56.2544 147.403 56.0042 147.635 55.8334C147.873 55.6568 148.131 55.5361 148.41 55.4714C148.688 55.4007 149.022 55.3654 149.41 55.3654C150.002 55.3654 150.582 55.4508 151.151 55.6215V56.5929C150.681 56.4575 150.226 56.3898 149.785 56.3898C149.222 56.3898 148.822 56.4869 148.584 56.6812C148.352 56.8696 148.236 57.2463 148.236 57.8115C148.236 58.3708 148.352 58.7475 148.584 58.9418C148.822 59.1361 149.222 59.2332 149.785 59.2332C150.237 59.2332 150.707 59.1331 151.195 58.933V59.9573C150.991 60.0574 150.736 60.131 150.429 60.1781C150.127 60.2311 149.878 60.2576 149.68 60.2576C149.239 60.2576 148.862 60.2252 148.549 60.1604C148.236 60.0898 147.951 59.9691 147.696 59.7984C147.441 59.6218 147.249 59.3716 147.122 59.0478C146.994 58.7181 146.93 58.306 146.93 57.8115Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M151.686 56.4781V55.4537H152.513L152.652 54.2881H153.784V55.4537H155.281V56.4781H153.784V58.0941C153.784 58.5238 153.83 58.8211 153.923 58.986C154.022 59.1449 154.222 59.2244 154.524 59.2244C154.848 59.2244 155.165 59.1449 155.472 58.986V59.9926C155.089 60.1693 154.666 60.2576 154.202 60.2576C153.053 60.2576 152.478 59.6865 152.478 58.5444V56.4781H151.686Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M159.905 62.0855H158.6V55.4537H159.644L159.775 55.8688C160.169 55.5332 160.688 55.3654 161.332 55.3654C161.675 55.3654 161.965 55.4007 162.203 55.4714C162.446 55.542 162.667 55.6657 162.864 55.8423C163.067 56.013 163.218 56.2632 163.317 56.5929C163.415 56.9167 163.465 57.3199 163.465 57.8027C163.465 58.2736 163.41 58.6769 163.299 59.0125C163.189 59.3421 163.032 59.5953 162.829 59.7719C162.626 59.9485 162.403 60.0751 162.159 60.1516C161.921 60.2222 161.646 60.2576 161.332 60.2576C160.752 60.2576 160.276 60.1045 159.905 59.7984V62.0855ZM161.019 56.3986C160.613 56.3986 160.323 56.5134 160.149 56.743C159.981 56.9667 159.896 57.3199 159.896 57.8027C159.896 58.3148 159.978 58.6828 160.14 58.9065C160.303 59.1243 160.596 59.2332 161.019 59.2332C161.431 59.2332 161.724 59.142 161.898 58.9595C162.072 58.7711 162.159 58.3855 162.159 57.8027C162.159 57.5024 162.136 57.2581 162.09 57.0697C162.049 56.8813 161.976 56.7401 161.872 56.6459C161.768 56.5458 161.652 56.481 161.524 56.4516C161.396 56.4163 161.228 56.3986 161.019 56.3986Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M164.481 60.1693V55.4537H165.786V60.1693H164.481ZM164.455 54.4912V53.3874H165.812V54.4912H164.455Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M169.42 60.2576C168.556 60.2576 167.903 60.0692 167.462 59.6924C167.021 59.3156 166.8 58.6857 166.8 57.8027C166.8 57.3082 166.853 56.899 166.957 56.5752C167.062 56.2514 167.221 56.0042 167.436 55.8334C167.656 55.6568 167.906 55.5361 168.184 55.4714C168.463 55.4007 168.805 55.3654 169.211 55.3654C170.012 55.3654 170.592 55.5126 170.952 55.807C171.311 56.0954 171.491 56.5811 171.491 57.264V58.2442H168.141C168.187 58.6033 168.312 58.8623 168.515 59.0213C168.718 59.1743 169.046 59.2509 169.498 59.2509C169.928 59.2509 170.528 59.1743 171.3 59.0213V59.9662C170.696 60.1604 170.07 60.2576 169.42 60.2576ZM169.211 56.3898C168.822 56.3898 168.55 56.4634 168.393 56.6105C168.242 56.7518 168.152 57.0079 168.123 57.3788H170.177V57.264C170.177 56.9638 170.102 56.743 169.951 56.6017C169.8 56.4604 169.553 56.3898 169.211 56.3898Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M172.121 57.8115C172.121 57.3229 172.179 56.9137 172.295 56.584C172.417 56.2544 172.594 56.0042 172.826 55.8334C173.064 55.6568 173.322 55.5361 173.6 55.4714C173.879 55.4007 174.212 55.3654 174.601 55.3654C175.193 55.3654 175.773 55.4508 176.342 55.6215V56.5929C175.872 56.4575 175.416 56.3898 174.975 56.3898C174.412 56.3898 174.012 56.4869 173.774 56.6812C173.542 56.8696 173.426 57.2463 173.426 57.8115C173.426 58.3708 173.542 58.7475 173.774 58.9418C174.012 59.1361 174.412 59.2332 174.975 59.2332C175.428 59.2332 175.898 59.1331 176.385 58.933V59.9573C176.182 60.0574 175.927 60.131 175.619 60.1781C175.318 60.2311 175.068 60.2576 174.871 60.2576C174.43 60.2576 174.053 60.2252 173.739 60.1604C173.426 60.0898 173.142 59.9691 172.887 59.7984C172.631 59.6218 172.44 59.3716 172.312 59.0478C172.185 58.7181 172.121 58.306 172.121 57.8115Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M179.627 60.2576C178.763 60.2576 178.11 60.0692 177.669 59.6924C177.228 59.3156 177.008 58.6857 177.008 57.8027C177.008 57.3082 177.06 56.899 177.164 56.5752C177.269 56.2514 177.428 56.0042 177.643 55.8334C177.863 55.6568 178.113 55.5361 178.391 55.4714C178.67 55.4007 179.012 55.3654 179.418 55.3654C180.219 55.3654 180.799 55.5126 181.159 55.807C181.518 56.0954 181.698 56.5811 181.698 57.264V58.2442H178.348C178.394 58.6033 178.519 58.8623 178.722 59.0213C178.925 59.1743 179.253 59.2509 179.705 59.2509C180.135 59.2509 180.735 59.1743 181.507 59.0213V59.9662C180.904 60.1604 180.277 60.2576 179.627 60.2576ZM179.418 56.3898C179.03 56.3898 178.757 56.4634 178.6 56.6105C178.449 56.7518 178.359 57.0079 178.33 57.3788H180.384V57.264C180.384 56.9638 180.309 56.743 180.158 56.6017C180.007 56.4604 179.761 56.3898 179.418 56.3898Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M187.974 60.1516C187.696 60.2222 187.356 60.2576 186.956 60.2576C186.556 60.2576 186.216 60.2222 185.938 60.1516C185.665 60.081 185.421 59.9573 185.207 59.7807C184.992 59.6041 184.833 59.3539 184.728 59.0301C184.624 58.7004 184.572 58.2942 184.572 57.8115C184.572 57.3229 184.624 56.9167 184.728 56.5929C184.833 56.2632 184.992 56.013 185.207 55.8423C185.421 55.6657 185.665 55.542 185.938 55.4714C186.216 55.4007 186.556 55.3654 186.956 55.3654C187.356 55.3654 187.693 55.4007 187.966 55.4714C188.244 55.542 188.491 55.6657 188.705 55.8423C188.92 56.013 189.08 56.2632 189.184 56.5929C189.288 56.9167 189.341 57.3229 189.341 57.8115C189.341 58.2942 189.288 58.7004 189.184 59.0301C189.08 59.3539 188.92 59.6041 188.705 59.7807C188.491 59.9573 188.247 60.081 187.974 60.1516ZM187.783 56.6812C187.615 56.4869 187.339 56.3898 186.956 56.3898C186.573 56.3898 186.298 56.4869 186.129 56.6812C185.961 56.8755 185.877 57.2522 185.877 57.8115C185.877 58.3649 185.961 58.7416 186.129 58.9418C186.303 59.1361 186.579 59.2332 186.956 59.2332C187.333 59.2332 187.606 59.1361 187.774 58.9418C187.948 58.7416 188.035 58.3649 188.035 57.8115C188.035 57.2522 187.951 56.8755 187.783 56.6812Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M189.77 55.4537H190.605V54.968C190.605 54.344 190.756 53.8878 191.058 53.5993C191.359 53.305 191.818 53.1578 192.433 53.1578C192.787 53.1578 193.164 53.2196 193.564 53.3432V54.2793C193.222 54.2086 192.917 54.1733 192.65 54.1733C192.389 54.1733 192.201 54.2351 192.085 54.3587C191.969 54.4824 191.911 54.6855 191.911 54.968V55.4537H193.364V56.4781H191.911V60.1693H190.605V56.4781H189.77V55.4537Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M196.479 53.2461H197.784V60.1693H196.479V53.2461Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M198.799 58.6769C198.799 58.1117 198.941 57.7291 199.225 57.5289C199.509 57.3288 199.965 57.2287 200.591 57.2287C201.015 57.2287 201.436 57.2728 201.853 57.3611V57.2022C201.853 56.9019 201.758 56.69 201.566 56.5664C201.38 56.4369 201.087 56.3721 200.687 56.3721C200.246 56.3721 199.744 56.4486 199.181 56.6017V55.6657C199.814 55.4655 200.409 55.3654 200.966 55.3654C201.412 55.3654 201.784 55.4066 202.08 55.4891C202.375 55.5656 202.599 55.6892 202.75 55.8599C202.906 56.0248 203.014 56.2102 203.072 56.4163C203.13 56.6164 203.159 56.8666 203.159 57.1669V60.1693H202.071L201.958 59.8602C201.499 60.1251 201.021 60.2576 200.522 60.2576C199.901 60.2576 199.457 60.1398 199.19 59.9043C198.929 59.663 198.799 59.2538 198.799 58.6769ZM200.948 58.1382C200.675 58.1382 200.467 58.1794 200.322 58.2619C200.177 58.3384 200.104 58.4826 200.104 58.6946C200.104 58.8535 200.116 58.9742 200.139 59.0566C200.162 59.139 200.229 59.2097 200.339 59.2685C200.449 59.3274 200.615 59.3568 200.835 59.3568C201.235 59.3568 201.575 59.2744 201.853 59.1096V58.2354C201.546 58.1706 201.244 58.1382 200.948 58.1382Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M204.391 60.1693V55.4537H205.488L205.592 55.7805C206.19 55.5038 206.764 55.3654 207.316 55.3654C208.447 55.3654 209.013 56.0277 209.013 57.3523V60.1693H207.699V57.7409C207.693 57.2051 207.626 56.849 207.498 56.6723C207.377 56.4957 207.153 56.4074 206.828 56.4074C206.503 56.4074 206.126 56.5134 205.697 56.7253V60.1693H204.391Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M211.996 60.2576C211.654 60.2576 211.361 60.2222 211.117 60.1516C210.874 60.081 210.65 59.9603 210.447 59.7895C210.25 59.6129 210.102 59.3627 210.003 59.0389C209.905 58.7093 209.856 58.3031 209.856 57.8203C209.856 57.3258 209.905 56.9137 210.003 56.584C210.102 56.2544 210.253 56.0042 210.456 55.8334C210.659 55.6568 210.88 55.5361 211.117 55.4714C211.361 55.4007 211.654 55.3654 211.996 55.3654C212.513 55.3654 212.986 55.4832 213.415 55.7186V53.2461H214.72V60.1693H213.685L213.563 59.7542C213.18 60.0898 212.658 60.2576 211.996 60.2576ZM213.18 56.7253C213.018 56.5075 212.727 56.3986 212.31 56.3986C211.892 56.3986 211.596 56.4899 211.422 56.6723C211.254 56.8548 211.17 57.2375 211.17 57.8203C211.17 58.2206 211.213 58.5238 211.3 58.7299C211.393 58.9359 211.515 59.0713 211.666 59.1361C211.817 59.195 212.031 59.2244 212.31 59.2244C212.71 59.2244 212.994 59.1125 213.163 58.8888C213.337 58.6651 213.424 58.309 213.424 57.8203C213.424 57.3082 213.342 56.9432 213.18 56.7253Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M218.781 60.1693L217.632 55.4537H218.894L219.773 59.1449H219.843L220.87 55.7451H221.905L222.932 59.1449H223.002L223.872 55.4537H225.134L223.994 60.1693H222.21L221.383 57.3258L220.565 60.1693H218.781Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M225.8 60.1693V55.4537H227.105V60.1693H225.8ZM225.774 54.4912V53.3874H227.132V54.4912H225.774Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M227.989 56.4781V55.4537H228.816L228.955 54.2881H230.086V55.4537H231.583V56.4781H230.086V58.0941C230.086 58.5238 230.133 58.8211 230.226 58.986C230.324 59.1449 230.524 59.2244 230.826 59.2244C231.151 59.2244 231.467 59.1449 231.775 58.986V59.9926C231.392 60.1693 230.968 60.2576 230.504 60.2576C229.355 60.2576 228.781 59.6865 228.781 58.5444V56.4781H227.989Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M233.964 60.1693H232.659V53.2461H233.964V55.7186C234.504 55.4832 235.046 55.3654 235.591 55.3654C236.7 55.3654 237.262 56.0042 237.28 57.2817V60.1693H235.966V57.6967C235.96 57.1727 235.89 56.8284 235.757 56.6635C235.629 56.4928 235.409 56.4074 235.095 56.4074C234.765 56.4074 234.388 56.5075 233.964 56.7077V60.1693Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M242.324 60.2576C241.738 60.2576 241.303 60.1192 241.019 59.8425C240.735 59.56 240.59 59.0831 240.584 58.412V55.4537H241.889V57.9263C241.895 58.462 241.953 58.8123 242.063 58.9771C242.179 59.1361 242.406 59.2156 242.742 59.2156C243.061 59.2156 243.436 59.1125 243.865 58.9065V55.4537H245.17V60.1693H244.074L243.978 59.8602C243.38 60.1251 242.829 60.2576 242.324 60.2576Z" fill="#9B9AA0" />
            <path className="logo__slogan" d="M246.058 56.8666C246.058 55.8658 246.757 55.3654 248.155 55.3654C248.811 55.3654 249.492 55.4655 250.2 55.6657V56.7165C249.486 56.4987 248.773 56.3898 248.059 56.3898C247.821 56.3898 247.644 56.4221 247.528 56.4869C247.418 56.5458 247.363 56.6635 247.363 56.8401C247.363 56.9814 247.404 57.0844 247.485 57.1492C247.572 57.214 247.76 57.2463 248.05 57.2463C248.346 57.2463 248.599 57.2552 248.808 57.2728C249.022 57.2905 249.24 57.3288 249.46 57.3876C249.687 57.4406 249.869 57.5171 250.009 57.6172C250.148 57.7173 250.261 57.8556 250.348 58.0323C250.435 58.2089 250.479 58.4208 250.479 58.6681C250.479 58.9918 250.426 59.2627 250.322 59.4805C250.217 59.6983 250.061 59.8631 249.852 59.975C249.643 60.081 249.417 60.1545 249.173 60.1958C248.929 60.237 248.636 60.2576 248.294 60.2576C247.488 60.2576 246.777 60.1398 246.162 59.9043V58.88C246.835 59.1155 247.575 59.2332 248.381 59.2332C248.683 59.2332 248.889 59.195 248.999 59.1184C249.115 59.0419 249.173 58.9094 249.173 58.721C249.173 58.5444 249.098 58.4296 248.947 58.3767C248.802 58.3178 248.558 58.2883 248.216 58.2883C247.438 58.2883 246.884 58.1883 246.554 57.9881C246.223 57.7821 246.058 57.4082 246.058 56.8666Z" fill="#9B9AA0" />
            <defs>
                <linearGradient id="paint0_linear_2_71" x1="21.8898" y1="7.3205" x2="31.6277" y2="41.3307" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4BAA2C" />
                    <stop offset={1} stopColor="#008F2E" />
                </linearGradient>
                <linearGradient id="paint1_linear_2_71" x1="27.6863" y1="18.5291" x2="27.6863" y2="37.9687" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#01652F" />
                    <stop offset={1} stopColor="#077238" stopOpacity="0.78" />
                </linearGradient>
                <linearGradient id="paint2_linear_2_71" x1="19.6307" y1="18.5641" x2="19.6307" y2="37.9687" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4BAA2C" />
                    <stop offset={1} stopColor="#217406" />
                </linearGradient>
                <linearGradient id="paint3_linear_2_71" x1="93.7569" y1="12.7991" x2="93.7569" y2="55.9331" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3CA42C" />
                    <stop offset="0.58125" stopColor="#008F2E" />
                </linearGradient>
                <linearGradient id="paint4_linear_2_71" x1="93.7569" y1="12.7991" x2="93.7569" y2="55.9331" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3CA42C" />
                    <stop offset="0.58125" stopColor="#008F2E" />
                </linearGradient>
                <linearGradient id="paint5_linear_2_71" x1="93.7569" y1="12.7991" x2="93.7569" y2="55.9331" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3CA42C" />
                    <stop offset="0.58125" stopColor="#008F2E" />
                </linearGradient>
                <linearGradient id="paint6_linear_2_71" x1="93.7569" y1="12.7991" x2="93.7569" y2="55.9331" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3CA42C" />
                    <stop offset="0.58125" stopColor="#008F2E" />
                </linearGradient>
            </defs>
        </svg>

    )
};

export default LandMarkerDartLogo;