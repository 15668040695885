import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  loginDatas,
  getStoreAuthToken,
  authSlice,
} from "../../redux/auth/authStore";
import Authform from "../../views/auth/auth-form/authForm";

function SignInPage() {
  const [loading, setLoading] = useState(false);
  // hook
  const history = useHistory();
  const dispatch = useDispatch();

  const authToken = getStoreAuthToken();

  const { saveAuthInfoLogin } = authSlice.actions;

  const authLoginToken = useSelector((state) => state.auth.token);
  const redirectSaved = useSelector((state) => state.auth.redirectSaved);
  const isSaveAuthInfo = useSelector((state) => state.auth.isSaveAuthInfo);
  const checkAuthTokenHasError = useSelector((state) => state.auth.hasError);

  const handleLogin = (authInfo) => {
    if (!authLoginToken) {
      const { username, password } = authInfo;
      setLoading(true);
      dispatch(loginDatas({ username, password }));
    }
  };

  const handleSaveAuthInfoLogin = () => {
    dispatch(saveAuthInfoLogin(!isSaveAuthInfo));
  };

  useEffect(() => {
    if (!!checkAuthTokenHasError) setLoading(false);
  }, [checkAuthTokenHasError]);

  useEffect(() => {
    if (authLoginToken) {
      setLoading(false);
      history.push(redirectSaved);
    }
  }, [authLoginToken]);

  if (authToken) return <Redirect to={redirectSaved} />;

  return (
    <Authform
      onSubmit={handleLogin}
      onChecked={handleSaveAuthInfoLogin}
      loading={loading}
      isSaved={isSaveAuthInfo}
    />
  );
}

export default SignInPage;
