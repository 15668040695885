import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getStoreAuthToken } from "../redux/auth/authStore";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_RVECO_URL,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      "x-token": getStoreAuthToken(),
    },
  });

  return forward(operation);
});

const logoutLink = onError((data) => {
  // if (data?.response?.errors?.length > 0) {
  //   window.location.reload();
  // }
});
const client = new ApolloClient({
  cache: new InMemoryCache(),

  link: concat(authMiddleware, logoutLink.concat(httpLink)),
});

export default client;
