import { gql } from "@apollo/client";

const action = {
  getAllAppointment: gql`
    query GetAllAppointment(
      $name: String
      $page: Int
      $limit: Int
      $filter: Mixed
    ) {
      getAllAppointment(
        q: {
          search: $name
          page: $page
          limit: $limit
          filter: $filter
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          title
          typeAppointment
          startTime
          endTime
          content
          status
          priority
          userCreated {
            name
          }
        }
      }
    }
  `,
};

export default action;
