import { IconMenu } from "@douyinfe/semi-icons";
import { Dropdown } from "@douyinfe/semi-ui";
import { useContext, useMemo } from "react";
import { ThemeModeContext } from "../../App";
import history from "../../history";

const ResponsiveMenu = () => {
  const { themeMode } = useContext(ThemeModeContext);

  // River Group
  const riverGroupSub = useMemo(
    () => (
      <Dropdown.Item>
        <Dropdown position={"bottomRight"}>
          <Dropdown.Item onClick={() => history.push("/rivergroup/newsPage")}>
            Tin tức
          </Dropdown.Item>
        </Dropdown>
      </Dropdown.Item>
    ),
    []
  );

  //River Land
  const riverLandSub = useMemo(
    () => (
      <Dropdown.Item>
        <Dropdown
          position={"bottomRight"}
          render={
            <Dropdown.Menu style={{ marginTop: 10 }}>
              <Dropdown.Item>Danh sách tin tức</Dropdown.Item>
            </Dropdown.Menu>
          }
        >
          <Dropdown.Item>Tin tức</Dropdown.Item>
        </Dropdown>
      </Dropdown.Item>
    ),
    []
  );

  //River Hospitality
  const riverHospitalitySub = useMemo(
    () => (
      <>
        <Dropdown.Item>
          <Dropdown position={"left"} style={{ marginTop: "1rem" }}>
            <Dropdown.Item
              onClick={() => history.push("/riverHospitality/newsRHListPage")}
            >
              Tin tức
            </Dropdown.Item>
          </Dropdown>
        </Dropdown.Item>

        <Dropdown.Item>
          <Dropdown position={"left"} style={{ marginTop: "1rem" }}>
            <Dropdown.Item
              onClick={() => history.push("/riverHospitality/hotelListPage")}
            >
              Khách sạn
            </Dropdown.Item>
          </Dropdown>
        </Dropdown.Item>

        <Dropdown.Item>
          <Dropdown
            position={"left"}
            style={{ marginTop: "5rem" }}
            render={
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    history.push("/riverHospitality/campingLocationList")
                  }
                >
                  Danh điểm cắm trại
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => history.push("/riverHospitality/tentList")}
                >
                  Danh sách lều trại
                </Dropdown.Item>
              </Dropdown.Menu>
            }
          >
            <Dropdown.Item>Cắm trại</Dropdown.Item>
          </Dropdown>
        </Dropdown.Item>

        <Dropdown.Item>
          <Dropdown
            position={"left"}
            style={{ marginTop: "5rem" }}
            render={
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    history.push("/riverHospitality/hotelRoomBookInfo")
                  }
                >
                  Khách sạn
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => history.push("/riverHospitality/tentBookInfo")}
                >
                  Cắm trại
                </Dropdown.Item>
              </Dropdown.Menu>
            }
          >
            <Dropdown.Item>Quản lí booking</Dropdown.Item>
          </Dropdown>
        </Dropdown.Item>

        <Dropdown.Item>
          <Dropdown
            position={"left"}
            style={{ marginTop: "1rem" }}
            render={
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    history.push("/riverHospitality/roomUtilities")
                  }
                >
                  Tiện ích
                </Dropdown.Item>
              </Dropdown.Menu>
            }
          >
            <Dropdown.Item>Setting</Dropdown.Item>
          </Dropdown>
        </Dropdown.Item>
      </>
    ),
    []
  );

  return (
    <Dropdown
      trigger={"hover"}
      position={"bottomRight"}
      render={
        <Dropdown.Menu>
          <Dropdown position={"leftTop"} render={riverGroupSub}>
            <Dropdown.Item>RiverGroup</Dropdown.Item>
          </Dropdown>
          <Dropdown position={"leftTop"}>
            <Dropdown.Item>RiverTech</Dropdown.Item>
          </Dropdown>
          <Dropdown position={"leftTop"} render={riverLandSub}>
            <Dropdown.Item>RiverLand</Dropdown.Item>
          </Dropdown>

          <Dropdown position={"leftTop"} render={riverHospitalitySub}>
            <Dropdown.Item>RiverHospitality</Dropdown.Item>
          </Dropdown>
        </Dropdown.Menu>
      }
    >
      {themeMode.mode === "dark" ? (
        <IconMenu size="extra-large" />
      ) : (
        <IconMenu style={{ color: "#000" }} size="extra-large" />
      )}
    </Dropdown>
  );
};

export default ResponsiveMenu;
