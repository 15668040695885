import { gql } from "@apollo/client";

const action = {
  getAllRealEstateProject: gql`
    query getAllRealEstateProject($page: Int, $limit: Int, $name: String) {
      getAllRealEstateProject(
        q: {
          page: $page
          limit: $limit
          search: $name
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          title
          type
          overview
          pricePerm2
          provinceId
          districtId
          wardId
          categoryId
          rvGroupProject {
            timeLine
          }
          address
          previewImage
          overviewImages
          drawingImages
          interiorImages
          utilityImages
          street
          location
          saleType
          constructionName
          investorName
          legalConditions
          area
          convenience
          buildingCount
          apartmentCount
        }
        total
      }
    }
  `,
  getOneRealEstateProject: gql`
    query getOneRealEstateProject($id: ID!) {
      getOneRealEstateProject(id: $id) {
        id
        title
        type
        overview
        pricePerm2
        area
        saleType
        legalConditions
        constructionName
        investorName
        rvGroupProject {
          timeLine
        }
        location
        provinceId
        districtId
        wardId
        street
        categoryId
        previewImage
        overviewImages
        drawingImages
        interiorImages
        utilityImages
      }
    }
  `,
  createRealEstateProject: gql`
    mutation createRealEstateProject($data: CreateRealEstateProjectInput!) {
      createRealEstateProject(data: $data) {
        id
        rvGroupProject {
          timeLine
        }
      }
    }
  `,
  updateRealEstateProject: gql`
    mutation updateRealEstateProject(
      $id: ID!
      $data: UpdateRealEstateProjectInput!
    ) {
      updateRealEstateProject(data: $data, id: $id) {
        id
      }
    }
  `,
  getAllInvestor: gql`
    query getAllInvestor {
      getAllInvestor {
        id
        name
      }
    }
  `,
  getAllConstruction: gql`
    query getAllConstruction {
      getAllConstruction {
        id
        name
      }
    }
  `,
};

export default action;
