import { gql } from "@apollo/client";

const action = {
  getAllRvMakerProject: gql`
    query GetAllRvMakerProject($name: String, $page: Int, $limit: Int) {
      getAllRvMakerProject(
        q: {
          search: $name
          page: $page
          limit: $limit
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          title
          projectTags {
            id
            name
            rgbItemColor
          }
          projectIcon {
            id
            key
            image
          }
        }
      }
    }
  `,
  updateRvMakerProject: gql`
    mutation UpdateRVMakerProject($id: ID!, $data: UpdateRvMakerProjectInput!) {
      updateRvMakerProject(id: $id, data: $data) {
        id
      }
    }
  `,
  createRvMakerProject: gql`
    mutation CreateRvMakerProject($data: CreateRvMakerProjectInput!) {
      createRvMakerProject(data: $data) {
        id
      }
    }
  `,
};

export default action;
