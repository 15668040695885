import {
  IconBriefcase,
  IconCustomerSupport,
  IconFlipHorizontal,
  IconHome,
} from "@douyinfe/semi-icons";

const permissionActions = {
  booking: ["approve", "reject"],
  general: ["write", "edit", "delete"],
};

export const options = {
  authorizations: {
    hospitality: {
      key: "HOSPITALITY",
      text: "RiverHospitality",
      permissionKey: {
        fieldKey: "hosPermission",
        subKey: {
          listStaff: {
            text: "listStaff",
            action: [...permissionActions.general],
          },
          hotelProject: {
            text: "hotelProject",
            action: [...permissionActions.general],
          },
          campProject: {
            text: "campProject",
            action: [...permissionActions.general],
          },
          settingProject: {
            text: "settingProject",
            action: [...permissionActions.general],
          },
          hotelSales: {
            text: "hotelSales",
            action: [...permissionActions.booking],
          },
          campSales: {
            text: "campSales",
            action: [...permissionActions.booking],
          },
          newsMarketing: {
            text: "newsMarketing",
            action: [...permissionActions.general],
          },
        },
      },
      icon: <IconFlipHorizontal size="large" />,
    },

    tech: {
      key: "TECH",
      text: "RiverTech",
      icon: <IconCustomerSupport size="large" />,
    },

    group: {
      key: "GROUP",
      text: "RiverGroup",
      icon: <IconBriefcase size="large" />,
    },

    land: {
      key: "LAND",
      text: "RiverLand",
      icon: <IconHome size="large" />,
    },

    // authorization: {
    //     key: "AUTHORIZATION",
    //     text: "Khác",
    //     icon: <IconActivity size="large"/>
    // }
  },

  groups: {
    hr: {
      key: "hr",
      role: "isManagerStaff",
      text: "Phòng nhân sự",
      icon: "hr",
      department: "Accounting",
    },
    project: {
      key: "project",
      role: "isManagerProject",
      text: "Phòng dự án",
      icon: "project",
      department: "Project Division",
    },
    sale: {
      key: "sale",
      role: "isManagerSales",
      text: "Phòng sales",
      icon: "sale",
      department: "Sales",
    },
    marketing: {
      key: "marketing",
      role: "isManagerMaketing",
      text: "Phòng marketing",
      icon: "marketing",
      department: "Marketing",
    },
    other: {
      key: "other",
      text: "",
    },
    client: {
      key: "client",
      text: "Khách hàng",
      icon: "client",
      department: "Client",
    },
  },

  routeGroupPaths: {
    rivergroupPathName: "riverGroup",
    permissionPathname: "permission",
    riverHospitalityPathName: "riverHospitality",
    riverlandPathName: "riverLand",
  },

  tradingTypes: {
    'MUABAN': 'Mua bán',
  }
};
