const constants = {
  news: {
    getImageInContentRegex:
      /https?:\/\/[^/\s]+\/\S+\.(jpg|png|gif|jpeg|jfif|JPG|PNG|GIF|JPED|webp)[^\"\s]*/g,
  },
  STORE_NAME: "river-x-token",
  STORE_CURRENT_USER: "currentUser",
  EXPIRY_SAVE_AUTH_DATE: 1,
  LIMIT_ITEM_LIST: 10,
  defaultMarker: { lat: 10.823099, lng: 106.629662 },
};

export default constants;

export const LIST_STATUS = {
  PROCESSING: {
    VALUE: "PROCESSING",
    LABEL: "Đang xử lý",
  },

  DENIED: {
    VALUE: "DENIED",
    LABEL: "Từ chối",
  },

  ACCEPT: {
    VALUE: "ACCEPT",
    LABEL: "Chấp nhận",
  },

  SUCCESS: {
    VALUE: "SUCCESS",
    LABEL: "Thành công",
  },

  FAIL: {
    VALUE: "FAIL",
    LABEL: "Thất bại",
  },

  PENDING: {
    VALUE: "PENDING",
    LABEL: "Chưa giải quyết",
  },
};

export const LIST_PLANNING = {
  DEPOSIT: {
    VALUE: "DEPOSIT ",
    LABEL: "Nạp tiền",
  },

  PAY_PLANNING_CHECK: {
    VALUE: "PAY_PLANNING_CHECK",
    LABEL: "Thanh toán kiểm tra quy hoạch",
  },
};

export const LIST_PAYMENT = {
  LANDMARKER_WALLET: {
    VALUE: "LANDMARKER_WALLET",
    LABEL: "Ví Landmarker",
  },

  ATM_CARD: {
    VALUE: "ATM_CARD",
    LABEL: "Thẻ ATM",
  },

  CREDIT_CARD: {
    VALUE: "CREDIT_CARD",
    LABEL: "Thẻ quốc tế",
  },

  BANK_TRANSFER: {
    VALUE: "BANK_TRANSFER",
    LABEL: "Chuyển khoản",
  },

  "9PAY": {
    VALUE: "9PAY",
    LABEL: "9PAY",
  },
};

export const optionPolicy = [
  {
    value: "Đã có sổ",
    label: "Đã có sổ",
  },
  {
    value: "Đang chờ sổ",
    label: "Đang chờ sổ",
  },
];

export const optionLand = [
  {
    value: "RESIDENTIAL_LAND",
    label: "Đất thổ cư",
  },
  {
    value: "PROJECT_LAND",
    label: "Đất nền dự án",
  },
  {
    value: "INDUSTRIAL_LAND",
    label: "Đất công nghiệp",
  },
  {
    value: "AGRICULTURAL_LAND",
    label: "Đất nông nghiệp",
  },
];

export const optionSide = [
  {
    value: "Đông",
    label: "Đông",
  },
  {
    value: "Tây",
    label: "Tây",
  },
  {
    value: "Nam",
    label: "Nam",
  },
  {
    value: "Bắc",
    label: "Bắc",
  },
  {
    value: "Đông Bắc",
    label: "Đông Bắc",
  },
  {
    value: "Tây Bắc",
    label: "Tây Bắc",
  },
  {
    value: "Đông Nam",
    label: "Đông Nam",
  },
  {
    value: "Tây Nam",
    label: "Tây Nam",
  },
];

export const optionStatus = [
  {
    value: "PRIVATE",
    label: "Riêng tư",
  },
  {
    value: "PUBLIC",
    label: "Công khai",
  },
  {
    value: "CANCELED",
    label: "Huỷ bỏ",
  },
];

export const optionTypeNew = [
  {
    label: "Bất động sản",
    value: "REAL_ESTATE",
  },
  {
    label: "Tài chính",
    value: "FINANCE",
  },
  {
    label: "Ngân hàng",
    value: "BANK",
  },
  {
    label: "Khác",
    value: "OTHER",
  },
];
