const LandMarkerLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={258} height={64} viewBox="0 0 258 64" fill="none">
            <path className="logo__icon-wrapper" d="M24.9937 0C11.1904 0 0 11.1904 0 24.9937C0 39.7491 13.0126 54.5045 20.2585 61.5777C22.892 64.1475 27.0955 64.1475 29.7266 61.5777C36.9748 54.5045 49.9874 39.7491 49.9874 24.9937C49.9874 11.1904 38.7971 0 24.9937 0ZM24.9937 38.6267C17.4638 38.6267 11.3608 32.5236 11.3608 24.9937C11.3608 17.4638 17.4638 11.3608 24.9937 11.3608C32.5236 11.3608 38.6267 17.4638 38.6267 24.9937C38.6267 32.5236 32.5236 38.6267 24.9937 38.6267ZM36.3545 24.9937C36.3545 31.2581 31.2581 36.3545 24.9937 36.3545C18.7294 36.3545 13.6329 31.2581 13.6329 24.9937C13.6329 18.7294 18.7294 13.6329 24.9937 13.6329C31.2581 13.6329 36.3545 18.7294 36.3545 24.9937Z" fill="white" />
            <path className="logo__icon" d="M24.9897 7.77509C15.2507 7.77509 7.35527 15.6685 7.35527 25.4052C7.35527 35.8133 16.5364 46.2215 21.6487 51.2108C23.5068 53.0235 26.4725 53.0235 28.329 51.2108C33.4429 46.2215 42.624 35.8133 42.624 25.4052C42.624 15.6685 34.7286 7.77509 24.9897 7.77509ZM24.9897 35.0216C19.6769 35.0216 15.3709 30.7166 15.3709 25.4052C15.3709 20.0937 19.6769 15.7888 24.9897 15.7888C30.3024 15.7888 34.6084 20.0937 34.6084 25.4052C34.6084 30.7166 30.3024 35.0216 24.9897 35.0216ZM33.0053 25.4052C33.0053 29.8239 29.4095 33.4188 24.9897 33.4188C20.5698 33.4188 16.974 29.8239 16.974 25.4052C16.974 20.9864 20.5698 17.3915 24.9897 17.3915C29.4095 17.3915 33.0053 20.9864 33.0053 25.4052Z" fill="url(#paint0_linear_1_37)" />
            <circle cx="25.0243" cy="24.9191" r="17.1441" fill="url(#paint1_linear_1_37)" />
            <path className="logo__icon" d="M32.4491 21.4645C33.9723 23.1929 34.7133 25.1811 34.7086 27.4555C34.7016 30.7861 34.7065 34.1167 34.7063 37.4473C34.7062 37.6049 34.7063 37.7626 34.7063 37.9574C33.5898 37.9574 32.4932 37.9574 31.348 37.9574C31.348 37.7778 31.348 37.6087 31.348 37.4395C31.3475 34.0368 31.3527 30.6341 31.344 27.2314C31.34 25.7068 30.7128 24.4451 29.6467 23.384C29.5788 23.3165 29.4935 23.2664 29.3554 23.1623C29.3554 28.1296 29.3554 33.0335 29.3554 37.9658C28.2212 37.9658 27.1344 37.9658 25.995 37.9658C25.995 32.6211 25.995 27.2811 25.995 21.9153C25.3161 21.9153 24.6892 21.9153 24.0158 21.9153C24.0158 25.4463 24.0158 28.9711 24.0158 32.6471C23.5258 32.4855 23.0879 32.4085 22.7179 32.2069C21.4308 31.506 20.8232 30.3414 20.7076 28.9277C20.635 28.0393 20.6755 27.1412 20.6745 26.2474C20.6717 23.854 20.6738 21.4606 20.674 19.0671C20.674 18.9101 20.674 18.753 20.674 18.5306C20.8425 18.5306 20.9804 18.5306 21.1184 18.5306C22.6746 18.5306 24.2309 18.5231 25.7871 18.5323C28.4161 18.5479 30.6371 19.5061 32.4491 21.4645Z" fill="white" />
            <path className="logo__icon" d="M15.2701 29.074C15.2702 25.5485 15.2702 22.0654 15.2702 18.5634C16.4081 18.5634 17.4982 18.5634 18.6338 18.5634C18.6338 18.7393 18.6338 18.8934 18.6338 19.0475C18.6338 22.3473 18.6787 25.648 18.621 28.9468C18.5645 32.1792 21.078 34.5835 23.8749 34.6418C23.9021 34.6423 23.929 34.6563 23.991 34.674C23.991 35.762 23.991 36.8598 23.9905 37.9578C20.2548 38.1587 15.282 34.8866 15.2701 29.074Z" fill="white" />
            <path className="logo__name" d="M58.5817 17.3544H63.6116V43.6351H58.5817V17.3544Z" fill="white" />
            <path className="logo__name" d="M67.5189 37.97C67.5189 35.8246 68.0665 34.3721 69.1619 33.6122C70.2573 32.8524 72.0122 32.4725 74.4265 32.4725C76.0584 32.4725 77.6791 32.6401 79.2887 32.9753V32.372C79.2887 31.2322 78.9198 30.4277 78.1821 29.9584C77.4667 29.4668 76.3378 29.221 74.7953 29.221C73.0964 29.221 71.1627 29.5115 68.9943 30.0925V26.5393C71.4309 25.7794 73.7223 25.3995 75.8684 25.3995C77.5897 25.3995 79.0204 25.556 80.1605 25.8688C81.3006 26.1593 82.1613 26.6286 82.7425 27.2767C83.3461 27.9025 83.7596 28.6064 83.9832 29.3886C84.2067 30.1484 84.3185 31.0981 84.3185 32.2379V43.6351H80.127L79.6911 42.4618C77.925 43.4675 76.0807 43.9703 74.1582 43.9703C71.7663 43.9703 70.0561 43.5234 69.0278 42.6295C68.0218 41.7132 67.5189 40.1601 67.5189 37.97ZM75.8013 35.9252C74.7506 35.9252 73.9459 36.0816 73.387 36.3945C72.8281 36.685 72.5487 37.2325 72.5487 38.037C72.5487 38.6404 72.5934 39.0985 72.6828 39.4114C72.7722 39.7243 73.0293 39.9924 73.4541 40.2159C73.8788 40.4394 74.5159 40.5511 75.3654 40.5511C76.9079 40.5511 78.2156 40.2383 79.2887 39.6125V36.2939C78.1039 36.0481 76.9414 35.9252 75.8013 35.9252Z" fill="white" />
            <path className="logo__name" d="M89.0686 43.6351V25.7347H93.2936L93.696 26.975C95.9986 25.9247 98.2117 25.3995 100.335 25.3995C104.695 25.3995 106.874 27.9136 106.874 32.9418V43.6351H101.811V34.4167C101.788 32.3831 101.531 31.0311 101.04 30.3607C100.57 29.6903 99.7095 29.355 98.4576 29.355C97.2057 29.355 95.7526 29.7573 94.0984 30.5618V43.6351H89.0686Z" fill="white" />
            <path className="logo__name" d="M118.371 43.9703C117.052 43.9703 115.923 43.8362 114.984 43.568C114.045 43.2999 113.184 42.8418 112.402 42.1937C111.642 41.5233 111.072 40.5735 110.692 39.3444C110.312 38.0929 110.122 36.5509 110.122 34.7184C110.122 32.8412 110.312 31.2769 110.692 30.0255C111.072 28.774 111.653 27.8242 112.436 27.1762C113.218 26.5057 114.067 26.0476 114.984 25.8018C115.923 25.5336 117.052 25.3995 118.371 25.3995C120.36 25.3995 122.182 25.8465 123.837 26.7404V17.3544H128.866V43.6351H124.876L124.407 42.0596C122.931 43.3334 120.919 43.9703 118.371 43.9703ZM122.931 30.5618C122.305 29.7349 121.187 29.3215 119.578 29.3215C117.968 29.3215 116.828 29.6679 116.158 30.3607C115.509 31.0534 115.185 32.506 115.185 34.7184C115.185 36.2381 115.353 37.389 115.688 38.1711C116.046 38.9533 116.515 39.4673 117.097 39.7131C117.678 39.9366 118.505 40.0483 119.578 40.0483C121.12 40.0483 122.216 39.6237 122.864 38.7745C123.535 37.9253 123.87 36.5733 123.87 34.7184C123.87 32.7742 123.557 31.3887 122.931 30.5618Z" fill="white" />
            <path className="logo__name" d="M134.216 25.7347H138.205L138.675 26.9415C140.82 25.9135 143.021 25.3995 145.279 25.3995C147.357 25.3995 148.921 26.0476 149.972 27.3438C152.609 26.0476 155.034 25.3995 157.246 25.3995C159.101 25.3995 160.543 25.9694 161.571 27.1091C162.621 28.2265 163.157 30.0813 163.18 32.6736V43.6351H158.118V34.4838C158.118 31.0646 157.034 29.355 154.866 29.355C153.659 29.355 152.419 29.7014 151.145 30.3942C151.19 30.573 151.246 31.2434 151.313 32.4055V43.6351H146.284V34.4838C146.24 32.763 145.96 31.478 145.446 30.6288C144.932 29.7796 144.094 29.355 142.932 29.355C141.837 29.355 140.608 29.6791 139.245 30.3272V43.6351H134.216V25.7347Z" fill="white" />
            <path className="logo__name" d="M166.427 37.97C166.427 35.8246 166.974 34.3721 168.069 33.6122C169.164 32.8524 170.919 32.4725 173.332 32.4725C174.964 32.4725 176.584 32.6401 178.193 32.9753V32.372C178.193 31.2322 177.824 30.4277 177.087 29.9584C176.372 29.4668 175.243 29.221 173.701 29.221C172.003 29.221 170.069 29.5115 167.902 30.0925V26.5393C170.338 25.7794 172.628 25.3995 174.774 25.3995C176.495 25.3995 177.925 25.556 179.065 25.8688C180.204 26.1593 181.065 26.6286 181.646 27.2767C182.249 27.9025 182.663 28.6064 182.886 29.3886C183.11 30.1484 183.222 31.0981 183.222 32.2379V43.6351H179.031L178.595 42.4618C176.83 43.4675 174.986 43.9703 173.064 43.9703C170.673 43.9703 168.963 43.5234 167.935 42.6295C166.93 41.7132 166.427 40.1601 166.427 37.97ZM174.707 35.9252C173.656 35.9252 172.852 36.0816 172.293 36.3945C171.734 36.685 171.455 37.2325 171.455 38.037C171.455 38.6404 171.5 39.0985 171.589 39.4114C171.679 39.7243 171.936 39.9924 172.36 40.2159C172.785 40.4394 173.422 40.5511 174.271 40.5511C175.813 40.5511 177.12 40.2383 178.193 39.6125V36.2939C177.009 36.0481 175.847 35.9252 174.707 35.9252Z" fill="white" />
            <path className="logo__name" d="M187.97 43.6351V25.7347H191.993L192.529 27.1091C194.34 25.9694 196.496 25.3995 198.999 25.3995C199.826 25.3995 200.418 25.4107 200.776 25.4331V29.3886C200.217 29.3215 199.625 29.288 198.999 29.288C197.211 29.288 195.211 29.9026 192.999 31.1317V43.6351H187.97Z" fill="white" />
            <path className="logo__name" d="M203.684 17.3544H208.712V31.8356H210.69L215.752 25.7347H221.92L215.249 33.7798L222.758 43.6351H216.758L210.724 35.6906H208.712V43.6351H203.684V17.3544Z" fill="white" />
            <path className="logo__name" d="M233.283 43.9703C229.953 43.9703 227.439 43.2552 225.74 41.8249C224.042 40.3947 223.193 38.0035 223.193 34.6514C223.193 32.7742 223.394 31.2211 223.796 29.9919C224.198 28.7628 224.813 27.8242 225.64 27.1762C226.489 26.5057 227.45 26.0476 228.523 25.8018C229.595 25.5336 230.914 25.3995 232.478 25.3995C235.562 25.3995 237.797 25.9582 239.183 27.0756C240.568 28.1706 241.261 30.0143 241.261 32.6066V36.3275H228.355C228.534 37.6907 229.014 38.6739 229.796 39.2773C230.579 39.8584 231.841 40.1489 233.585 40.1489C235.238 40.1489 237.551 39.8584 240.524 39.2773V42.8641C238.199 43.6016 235.786 43.9703 233.283 43.9703ZM232.478 29.288C230.981 29.288 229.931 29.5673 229.327 30.126C228.746 30.6624 228.4 31.6345 228.288 33.0424H236.199V32.6066C236.199 31.4669 235.909 30.6288 235.328 30.0925C234.747 29.5562 233.797 29.288 232.478 29.288Z" fill="white" />
            <path className="logo__name" d="M245.194 43.6351V25.7347H249.217L249.753 27.1091C251.564 25.9694 253.72 25.3995 256.223 25.3995C257.05 25.3995 257.642 25.4107 258 25.4331V29.3886C257.441 29.3215 256.849 29.288 256.223 29.288C254.435 29.288 252.435 29.9026 250.223 31.1317V43.6351H245.194Z" fill="white" />
            <path className="logo__slogan" d="M61.033 60.4432C60.6911 60.4432 60.3984 60.4084 60.155 60.3389C59.9116 60.2693 59.6884 60.1505 59.4856 59.9824C59.2885 59.8086 59.1407 59.5623 59.0422 59.2435C58.9437 58.9189 58.8944 58.519 58.8944 58.0438C58.8944 57.5569 58.9437 57.1512 59.0422 56.8267C59.1407 56.5021 59.2914 56.2558 59.4943 56.0877C59.6971 55.9138 59.9174 55.795 60.155 55.7313C60.3984 55.6617 60.6911 55.6269 61.033 55.6269C61.5489 55.6269 62.0212 55.7429 62.4501 55.9747V53.5405H63.7541V60.3563H62.7196L62.5979 59.9477C62.2154 60.278 61.6938 60.4432 61.033 60.4432ZM62.2154 56.9658C62.0531 56.7513 61.7633 56.6441 61.346 56.6441C60.9287 56.6441 60.6331 56.7339 60.4593 56.9136C60.2912 57.0933 60.2071 57.47 60.2071 58.0438C60.2071 58.4379 60.2506 58.7364 60.3376 58.9392C60.4303 59.1421 60.552 59.2754 60.7027 59.3391C60.8534 59.3971 61.0678 59.4261 61.346 59.4261C61.7459 59.4261 62.0299 59.3159 62.198 59.0957C62.3719 58.8755 62.4588 58.5248 62.4588 58.0438C62.4588 57.5395 62.3777 57.1802 62.2154 56.9658Z" fill="white" />
            <path className="logo__slogan" d="M65.1606 60.3563V55.7139H66.4647V60.3563H65.1606ZM65.1345 54.7663V53.6796H66.4907V54.7663H65.1345Z" fill="white" />
            <path className="logo__slogan" d="M67.3473 57.1049C67.3473 56.1196 68.0456 55.6269 69.4424 55.6269C70.0973 55.6269 70.7783 55.7255 71.4854 55.9225V56.9571C70.7725 56.7426 70.0597 56.6354 69.3468 56.6354C69.1092 56.6354 68.9324 56.6673 68.8165 56.731C68.7064 56.789 68.6513 56.9049 68.6513 57.0788C68.6513 57.2179 68.6919 57.3193 68.773 57.3831C68.86 57.4468 69.0483 57.4787 69.3381 57.4787C69.6337 57.4787 69.8858 57.4874 70.0944 57.5048C70.3089 57.5222 70.5262 57.5598 70.7465 57.6178C70.9725 57.6699 71.1551 57.7453 71.2942 57.8438C71.4333 57.9423 71.5463 58.0785 71.6332 58.2524C71.7202 58.4263 71.7636 58.6349 71.7636 58.8784C71.7636 59.1971 71.7115 59.4637 71.6071 59.6782C71.5028 59.8926 71.3463 60.0549 71.1377 60.165C70.929 60.2693 70.703 60.3418 70.4596 60.3824C70.2162 60.4229 69.9235 60.4432 69.5815 60.4432C68.7759 60.4432 68.0659 60.3273 67.4516 60.0955V59.087C68.1239 59.3188 68.8629 59.4348 69.6685 59.4348C69.9698 59.4348 70.1756 59.3971 70.2857 59.3217C70.4016 59.2464 70.4596 59.116 70.4596 58.9305C70.4596 58.7566 70.3842 58.6436 70.2335 58.5915C70.0887 58.5335 69.8452 58.5045 69.5033 58.5045C68.7266 58.5045 68.1732 58.406 67.8428 58.2089C67.5124 58.0061 67.3473 57.6381 67.3473 57.1049Z" fill="white" />
            <path className="logo__slogan" d="M72.2575 58.0351C72.2575 57.554 72.3154 57.1512 72.4313 56.8267C72.553 56.5021 72.7298 56.2558 72.9616 56.0877C73.1993 55.9138 73.4572 55.795 73.7354 55.7313C74.0136 55.6617 74.3468 55.6269 74.7351 55.6269C75.3263 55.6269 75.9059 55.711 76.4739 55.8791V56.8354C76.0044 56.7021 75.5494 56.6354 75.109 56.6354C74.5468 56.6354 74.1469 56.731 73.9093 56.9223C73.6774 57.1078 73.5615 57.4787 73.5615 58.0351C73.5615 58.5857 73.6774 58.9566 73.9093 59.1479C74.1469 59.3391 74.5468 59.4348 75.109 59.4348C75.561 59.4348 76.0305 59.3362 76.5173 59.1392V60.1476C76.3145 60.2462 76.0595 60.3186 75.7523 60.365C75.4509 60.4171 75.2017 60.4432 75.0046 60.4432C74.5642 60.4432 74.1874 60.4113 73.8745 60.3476C73.5615 60.278 73.2775 60.1592 73.0225 59.9911C72.7675 59.8173 72.5762 59.571 72.4487 59.2522C72.3212 58.9276 72.2575 58.5219 72.2575 58.0351Z" fill="white" />
            <path className="logo__slogan" d="M80.5383 60.3389C80.2601 60.4084 79.9211 60.4432 79.5212 60.4432C79.1213 60.4432 78.7822 60.4084 78.504 60.3389C78.2316 60.2693 77.9882 60.1476 77.7738 59.9738C77.5593 59.7999 77.3999 59.5536 77.2956 59.2348C77.1913 58.9102 77.1391 58.5103 77.1391 58.0351C77.1391 57.554 77.1913 57.1541 77.2956 56.8354C77.3999 56.5108 77.5593 56.2645 77.7738 56.0964C77.9882 55.9225 78.2316 55.8008 78.504 55.7313C78.7822 55.6617 79.1213 55.6269 79.5212 55.6269C79.9211 55.6269 80.2572 55.6617 80.5296 55.7313C80.8078 55.8008 81.0542 55.9225 81.2686 56.0964C81.483 56.2645 81.6424 56.5108 81.7468 56.8354C81.8511 57.1541 81.9032 57.554 81.9032 58.0351C81.9032 58.5103 81.8511 58.9102 81.7468 59.2348C81.6424 59.5536 81.483 59.7999 81.2686 59.9738C81.0542 60.1476 80.8107 60.2693 80.5383 60.3389ZM80.3471 56.9223C80.179 56.731 79.9037 56.6354 79.5212 56.6354C79.1387 56.6354 78.8634 56.731 78.6953 56.9223C78.5272 57.1136 78.4432 57.4845 78.4432 58.0351C78.4432 58.5799 78.5272 58.9508 78.6953 59.1479C78.8692 59.3391 79.1445 59.4348 79.5212 59.4348C79.8979 59.4348 80.1703 59.3391 80.3384 59.1479C80.5123 58.9508 80.5992 58.5799 80.5992 58.0351C80.5992 57.4845 80.5152 57.1136 80.3471 56.9223Z" fill="white" />
            <path className="logo__slogan" d="M82.1825 55.7139H83.4865L84.721 59.3478H84.9296L86.1641 55.7139H87.4682L85.8686 60.3563H83.7734L82.1825 55.7139Z" fill="white" />
            <path className="logo__slogan" d="M90.3598 60.4432C89.4962 60.4432 88.8442 60.2577 88.4037 59.8868C87.9632 59.5159 87.743 58.8957 87.743 58.0264C87.743 57.5395 87.7951 57.1367 87.8995 56.818C88.0038 56.4992 88.1632 56.2558 88.3776 56.0877C88.5979 55.9138 88.8471 55.795 89.1253 55.7313C89.4035 55.6617 89.7454 55.6269 90.1511 55.6269C90.9509 55.6269 91.5305 55.7718 91.8898 56.0616C92.2492 56.3456 92.4288 56.8238 92.4288 57.4961V58.4611H89.0818C89.1282 58.8146 89.2528 59.0696 89.4556 59.2261C89.6585 59.3768 89.9859 59.4521 90.438 59.4521C90.8669 59.4521 91.4668 59.3768 92.2376 59.2261V60.1563C91.6348 60.3476 91.0089 60.4432 90.3598 60.4432ZM90.1511 56.6354C89.7628 56.6354 89.4904 56.7079 89.3339 56.8527C89.1832 56.9918 89.0934 57.244 89.0644 57.6091H91.1161V57.4961C91.1161 57.2005 91.0408 56.9831 90.8901 56.8441C90.7394 56.705 90.4931 56.6354 90.1511 56.6354Z" fill="white" />
            <path className="logo__slogan" d="M93.4489 60.3563V55.7139H94.4921L94.6312 56.0703C95.1006 55.7747 95.6599 55.6269 96.3091 55.6269C96.5235 55.6269 96.6771 55.6298 96.7698 55.6356V56.6615C96.6249 56.6441 96.4713 56.6354 96.3091 56.6354C95.8454 56.6354 95.3267 56.7948 94.7529 57.1136V60.3563H93.4489Z" fill="white" />
            <path className="logo__slogan" d="M100.887 60.3563L99.0264 55.7139H100.356L101.704 59.1913H101.808L103.156 55.7139H104.486L101.86 62.2428H100.539L101.295 60.3563H100.887Z" fill="white" />
            <path className="logo__slogan" d="M108.164 60.3389C107.886 60.4084 107.547 60.4432 107.147 60.4432C106.747 60.4432 106.408 60.4084 106.13 60.3389C105.858 60.2693 105.614 60.1476 105.4 59.9738C105.185 59.7999 105.026 59.5536 104.922 59.2348C104.817 58.9102 104.765 58.5103 104.765 58.0351C104.765 57.554 104.817 57.1541 104.922 56.8354C105.026 56.5108 105.185 56.2645 105.4 56.0964C105.614 55.9225 105.858 55.8008 106.13 55.7313C106.408 55.6617 106.747 55.6269 107.147 55.6269C107.547 55.6269 107.883 55.6617 108.156 55.7313C108.434 55.8008 108.68 55.9225 108.895 56.0964C109.109 56.2645 109.268 56.5108 109.373 56.8354C109.477 57.1541 109.529 57.554 109.529 58.0351C109.529 58.5103 109.477 58.9102 109.373 59.2348C109.268 59.5536 109.109 59.7999 108.895 59.9738C108.68 60.1476 108.437 60.2693 108.164 60.3389ZM107.973 56.9223C107.805 56.731 107.53 56.6354 107.147 56.6354C106.765 56.6354 106.489 56.731 106.321 56.9223C106.153 57.1136 106.069 57.4845 106.069 58.0351C106.069 58.5799 106.153 58.9508 106.321 59.1479C106.495 59.3391 106.77 59.4348 107.147 59.4348C107.524 59.4348 107.796 59.3391 107.964 59.1479C108.138 58.9508 108.225 58.5799 108.225 58.0351C108.225 57.4845 108.141 57.1136 107.973 56.9223Z" fill="white" />
            <path className="logo__slogan" d="M112.112 60.4432C111.527 60.4432 111.092 60.307 110.808 60.0346C110.524 59.7564 110.379 59.287 110.374 58.6262V55.7139H111.678V58.1481C111.683 58.6755 111.741 59.0204 111.851 59.1826C111.967 59.3391 112.193 59.4174 112.53 59.4174C112.848 59.4174 113.222 59.3159 113.651 59.1131V55.7139H114.955V60.3563H113.86L113.764 60.052C113.167 60.3128 112.617 60.4432 112.112 60.4432Z" fill="white" />
            <path className="logo__slogan" d="M116.363 60.3563V55.7139H117.406L117.545 56.0703C118.015 55.7747 118.574 55.6269 119.223 55.6269C119.438 55.6269 119.591 55.6298 119.684 55.6356V56.6615C119.539 56.6441 119.385 56.6354 119.223 56.6354C118.76 56.6354 118.241 56.7948 117.667 57.1136V60.3563H116.363Z" fill="white" />
            <path className="logo__slogan" d="M123.984 62.2428H122.679V55.7139H123.723L123.853 56.1225C124.247 55.7921 124.766 55.6269 125.409 55.6269C125.751 55.6269 126.041 55.6617 126.279 55.7313C126.522 55.8008 126.742 55.9225 126.939 56.0964C127.142 56.2645 127.293 56.5108 127.391 56.8354C127.49 57.1541 127.539 57.5511 127.539 58.0264C127.539 58.49 127.484 58.8871 127.374 59.2174C127.264 59.542 127.107 59.7912 126.905 59.9651C126.702 60.1389 126.479 60.2635 126.235 60.3389C125.998 60.4084 125.722 60.4432 125.409 60.4432C124.83 60.4432 124.354 60.2925 123.984 59.9911V62.2428ZM125.096 56.6441C124.691 56.6441 124.401 56.7571 124.227 56.9831C124.059 57.2034 123.975 57.5511 123.975 58.0264C123.975 58.5306 124.056 58.8928 124.218 59.1131C124.381 59.3275 124.673 59.4348 125.096 59.4348C125.508 59.4348 125.8 59.3449 125.974 59.1652C126.148 58.9798 126.235 58.6002 126.235 58.0264C126.235 57.7308 126.212 57.4903 126.166 57.3048C126.125 57.1193 126.053 56.9803 125.948 56.8875C125.844 56.789 125.728 56.7252 125.601 56.6963C125.473 56.6615 125.305 56.6441 125.096 56.6441Z" fill="white" />
            <path className="logo__slogan" d="M130.78 60.4432C129.916 60.4432 129.264 60.2577 128.824 59.8868C128.383 59.5159 128.163 58.8957 128.163 58.0264C128.163 57.5395 128.215 57.1367 128.32 56.818C128.424 56.4992 128.583 56.2558 128.798 56.0877C129.018 55.9138 129.267 55.795 129.546 55.7313C129.824 55.6617 130.166 55.6269 130.571 55.6269C131.371 55.6269 131.951 55.7718 132.31 56.0616C132.669 56.3456 132.849 56.8238 132.849 57.4961V58.4611H129.502C129.548 58.8146 129.673 59.0696 129.876 59.2261C130.079 59.3768 130.406 59.4521 130.858 59.4521C131.287 59.4521 131.887 59.3768 132.658 59.2261V60.1563C132.055 60.3476 131.429 60.4432 130.78 60.4432ZM130.571 56.6354C130.183 56.6354 129.911 56.7079 129.754 56.8527C129.603 56.9918 129.514 57.244 129.485 57.6091H131.536V57.4961C131.536 57.2005 131.461 56.9831 131.31 56.8441C131.16 56.705 130.913 56.6354 130.571 56.6354Z" fill="white" />
            <path className="logo__slogan" d="M133.869 60.3563V55.7139H134.912L135.051 56.0703C135.521 55.7747 136.08 55.6269 136.729 55.6269C136.944 55.6269 137.097 55.6298 137.19 55.6356V56.6615C137.045 56.6441 136.892 56.6354 136.729 56.6354C136.266 56.6354 135.747 56.7948 135.173 57.1136V60.3563H133.869Z" fill="white" />
            <path className="logo__slogan" d="M137.592 55.7139H138.427V55.2357C138.427 54.6214 138.578 54.1722 138.879 53.8882C139.18 53.5984 139.638 53.4535 140.253 53.4535C140.606 53.4535 140.983 53.5144 141.383 53.6361V54.5576C141.041 54.4881 140.737 54.4533 140.47 54.4533C140.209 54.4533 140.021 54.5142 139.905 54.6359C139.789 54.7576 139.731 54.9575 139.731 55.2357V55.7139H141.183V56.7223H139.731V60.3563H138.427V56.7223H137.592V55.7139Z" fill="white" />
            <path className="logo__slogan" d="M144.143 60.4432C143.28 60.4432 142.627 60.2577 142.187 59.8868C141.747 59.5159 141.526 58.8957 141.526 58.0264C141.526 57.5395 141.578 57.1367 141.683 56.818C141.787 56.4992 141.946 56.2558 142.161 56.0877C142.381 55.9138 142.63 55.795 142.909 55.7313C143.187 55.6617 143.529 55.6269 143.934 55.6269C144.734 55.6269 145.314 55.7718 145.673 56.0616C146.032 56.3456 146.212 56.8238 146.212 57.4961V58.4611H142.865C142.911 58.8146 143.036 59.0696 143.239 59.2261C143.442 59.3768 143.769 59.4521 144.221 59.4521C144.65 59.4521 145.25 59.3768 146.021 59.2261V60.1563C145.418 60.3476 144.792 60.4432 144.143 60.4432ZM143.934 56.6354C143.546 56.6354 143.274 56.7079 143.117 56.8527C142.967 56.9918 142.877 57.244 142.848 57.6091H144.899V57.4961C144.899 57.2005 144.824 56.9831 144.673 56.8441C144.523 56.705 144.276 56.6354 143.934 56.6354Z" fill="white" />
            <path className="logo__slogan" d="M146.841 58.0351C146.841 57.554 146.899 57.1512 147.015 56.8267C147.137 56.5021 147.313 56.2558 147.545 56.0877C147.783 55.9138 148.041 55.795 148.319 55.7313C148.597 55.6617 148.93 55.6269 149.319 55.6269C149.91 55.6269 150.489 55.711 151.057 55.8791V56.8354C150.588 56.7021 150.133 56.6354 149.692 56.6354C149.13 56.6354 148.73 56.731 148.493 56.9223C148.261 57.1078 148.145 57.4787 148.145 58.0351C148.145 58.5857 148.261 58.9566 148.493 59.1479C148.73 59.3391 149.13 59.4348 149.692 59.4348C150.145 59.4348 150.614 59.3362 151.101 59.1392V60.1476C150.898 60.2462 150.643 60.3186 150.336 60.365C150.034 60.4171 149.785 60.4432 149.588 60.4432C149.148 60.4432 148.771 60.4113 148.458 60.3476C148.145 60.278 147.861 60.1592 147.606 59.9911C147.351 59.8173 147.16 59.571 147.032 59.2522C146.905 58.9276 146.841 58.5219 146.841 58.0351Z" fill="white" />
            <path className="logo__slogan" d="M151.592 56.7223V55.7139H152.418L152.557 54.5663H153.687V55.7139H155.183V56.7223H153.687V58.3133C153.687 58.7364 153.734 59.029 153.826 59.1913C153.925 59.3478 154.125 59.4261 154.426 59.4261C154.751 59.4261 155.067 59.3478 155.374 59.1913V60.1824C154.991 60.3563 154.568 60.4432 154.105 60.4432C152.957 60.4432 152.383 59.881 152.383 58.7566V56.7223H151.592Z" fill="white" />
            <path className="logo__slogan" d="M159.802 62.2428H158.498V55.7139H159.541L159.672 56.1225C160.066 55.7921 160.585 55.6269 161.228 55.6269C161.57 55.6269 161.86 55.6617 162.097 55.7313C162.341 55.8008 162.561 55.9225 162.758 56.0964C162.961 56.2645 163.112 56.5108 163.21 56.8354C163.309 57.1541 163.358 57.5511 163.358 58.0264C163.358 58.49 163.303 58.8871 163.193 59.2174C163.083 59.542 162.926 59.7912 162.723 59.9651C162.52 60.1389 162.297 60.2635 162.054 60.3389C161.816 60.4084 161.541 60.4432 161.228 60.4432C160.648 60.4432 160.173 60.2925 159.802 59.9911V62.2428ZM160.915 56.6441C160.509 56.6441 160.22 56.7571 160.046 56.9831C159.878 57.2034 159.794 57.5511 159.794 58.0264C159.794 58.5306 159.875 58.8928 160.037 59.1131C160.199 59.3275 160.492 59.4348 160.915 59.4348C161.327 59.4348 161.619 59.3449 161.793 59.1652C161.967 58.9798 162.054 58.6002 162.054 58.0264C162.054 57.7308 162.031 57.4903 161.984 57.3048C161.944 57.1193 161.871 56.9803 161.767 56.8875C161.663 56.789 161.547 56.7252 161.419 56.6963C161.292 56.6615 161.124 56.6441 160.915 56.6441Z" fill="white" />
            <path className="logo__slogan" d="M164.373 60.3563V55.7139H165.677V60.3563H164.373ZM164.347 54.7663V53.6796H165.703V54.7663H164.347Z" fill="white" />
            <path className="logo__slogan" d="M169.307 60.4432C168.443 60.4432 167.791 60.2577 167.351 59.8868C166.91 59.5159 166.69 58.8957 166.69 58.0264C166.69 57.5395 166.742 57.1367 166.847 56.818C166.951 56.4992 167.11 56.2558 167.325 56.0877C167.545 55.9138 167.794 55.795 168.073 55.7313C168.351 55.6617 168.693 55.6269 169.098 55.6269C169.898 55.6269 170.478 55.7718 170.837 56.0616C171.196 56.3456 171.376 56.8238 171.376 57.4961V58.4611H168.029C168.075 58.8146 168.2 59.0696 168.403 59.2261C168.606 59.3768 168.933 59.4521 169.385 59.4521C169.814 59.4521 170.414 59.3768 171.185 59.2261V60.1563C170.582 60.3476 169.956 60.4432 169.307 60.4432ZM169.098 56.6354C168.71 56.6354 168.438 56.7079 168.281 56.8527C168.13 56.9918 168.041 57.244 168.012 57.6091H170.063V57.4961C170.063 57.2005 169.988 56.9831 169.837 56.8441C169.687 56.705 169.44 56.6354 169.098 56.6354Z" fill="white" />
            <path className="logo__slogan" d="M172.005 58.0351C172.005 57.554 172.063 57.1512 172.179 56.8267C172.3 56.5021 172.477 56.2558 172.709 56.0877C172.947 55.9138 173.205 55.795 173.483 55.7313C173.761 55.6617 174.094 55.6269 174.483 55.6269C175.074 55.6269 175.653 55.711 176.221 55.8791V56.8354C175.752 56.7021 175.297 56.6354 174.856 56.6354C174.294 56.6354 173.894 56.731 173.657 56.9223C173.425 57.1078 173.309 57.4787 173.309 58.0351C173.309 58.5857 173.425 58.9566 173.657 59.1479C173.894 59.3391 174.294 59.4348 174.856 59.4348C175.308 59.4348 175.778 59.3362 176.265 59.1392V60.1476C176.062 60.2462 175.807 60.3186 175.5 60.365C175.198 60.4171 174.949 60.4432 174.752 60.4432C174.312 60.4432 173.935 60.4113 173.622 60.3476C173.309 60.278 173.025 60.1592 172.77 59.9911C172.515 59.8173 172.324 59.571 172.196 59.2522C172.069 58.9276 172.005 58.5219 172.005 58.0351Z" fill="white" />
            <path className="logo__slogan" d="M179.503 60.4432C178.64 60.4432 177.988 60.2577 177.547 59.8868C177.107 59.5159 176.887 58.8957 176.887 58.0264C176.887 57.5395 176.939 57.1367 177.043 56.818C177.147 56.4992 177.307 56.2558 177.521 56.0877C177.741 55.9138 177.991 55.795 178.269 55.7313C178.547 55.6617 178.889 55.6269 179.295 55.6269C180.095 55.6269 180.674 55.7718 181.033 56.0616C181.393 56.3456 181.572 56.8238 181.572 57.4961V58.4611H178.225C178.272 58.8146 178.396 59.0696 178.599 59.2261C178.802 59.3768 179.13 59.4521 179.582 59.4521C180.01 59.4521 180.61 59.3768 181.381 59.2261V60.1563C180.778 60.3476 180.152 60.4432 179.503 60.4432ZM179.295 56.6354C178.906 56.6354 178.634 56.7079 178.478 56.8527C178.327 56.9918 178.237 57.244 178.208 57.6091H180.26V57.4961C180.26 57.2005 180.184 56.9831 180.034 56.8441C179.883 56.705 179.637 56.6354 179.295 56.6354Z" fill="white" />
            <path className="logo__slogan" d="M187.842 60.3389C187.564 60.4084 187.225 60.4432 186.825 60.4432C186.425 60.4432 186.086 60.4084 185.807 60.3389C185.535 60.2693 185.292 60.1476 185.077 59.9738C184.863 59.7999 184.703 59.5536 184.599 59.2348C184.495 58.9102 184.443 58.5103 184.443 58.0351C184.443 57.554 184.495 57.1541 184.599 56.8354C184.703 56.5108 184.863 56.2645 185.077 56.0964C185.292 55.9225 185.535 55.8008 185.807 55.7313C186.086 55.6617 186.425 55.6269 186.825 55.6269C187.225 55.6269 187.561 55.6617 187.833 55.7313C188.111 55.8008 188.358 55.9225 188.572 56.0964C188.786 56.2645 188.946 56.5108 189.05 56.8354C189.155 57.1541 189.207 57.554 189.207 58.0351C189.207 58.5103 189.155 58.9102 189.05 59.2348C188.946 59.5536 188.786 59.7999 188.572 59.9738C188.358 60.1476 188.114 60.2693 187.842 60.3389ZM187.651 56.9223C187.482 56.731 187.207 56.6354 186.825 56.6354C186.442 56.6354 186.167 56.731 185.999 56.9223C185.831 57.1136 185.747 57.4845 185.747 58.0351C185.747 58.5799 185.831 58.9508 185.999 59.1479C186.173 59.3391 186.448 59.4348 186.825 59.4348C187.201 59.4348 187.474 59.3391 187.642 59.1479C187.816 58.9508 187.903 58.5799 187.903 58.0351C187.903 57.4845 187.819 57.1136 187.651 56.9223Z" fill="white" />
            <path className="logo__slogan" d="M189.635 55.7139H190.47V55.2357C190.47 54.6214 190.621 54.1722 190.922 53.8882C191.223 53.5984 191.681 53.4535 192.296 53.4535C192.649 53.4535 193.026 53.5144 193.426 53.6361V54.5576C193.084 54.4881 192.78 54.4533 192.513 54.4533C192.252 54.4533 192.064 54.5142 191.948 54.6359C191.832 54.7576 191.774 54.9575 191.774 55.2357V55.7139H193.226V56.7223H191.774V60.3563H190.47V56.7223H189.635V55.7139Z" fill="white" />
            <path className="logo__slogan" d="M196.338 53.5405H197.642V60.3563H196.338V53.5405Z" fill="white" />
            <path className="logo__slogan" d="M198.655 58.8871C198.655 58.3307 198.797 57.9539 199.081 57.7569C199.365 57.5598 199.82 57.4613 200.445 57.4613C200.869 57.4613 201.289 57.5048 201.706 57.5917V57.4352C201.706 57.1396 201.61 56.931 201.419 56.8093C201.234 56.6818 200.941 56.618 200.541 56.618C200.101 56.618 199.599 56.6934 199.037 56.8441V55.9225C199.669 55.7255 200.263 55.6269 200.819 55.6269C201.266 55.6269 201.637 55.6675 201.932 55.7487C202.228 55.824 202.451 55.9457 202.602 56.1138C202.758 56.2761 202.865 56.4586 202.923 56.6615C202.981 56.8585 203.01 57.1049 203.01 57.4004V60.3563H201.923L201.81 60.052C201.353 60.3128 200.874 60.4432 200.376 60.4432C199.756 60.4432 199.312 60.3273 199.046 60.0955C198.785 59.8578 198.655 59.455 198.655 58.8871ZM200.802 58.3567C200.53 58.3567 200.321 58.3973 200.176 58.4785C200.031 58.5538 199.959 58.6958 199.959 58.9044C199.959 59.0609 199.97 59.1797 199.993 59.2609C200.017 59.342 200.083 59.4116 200.193 59.4695C200.304 59.5275 200.469 59.5565 200.689 59.5565C201.089 59.5565 201.428 59.4753 201.706 59.313V58.4524C201.399 58.3886 201.098 58.3567 200.802 58.3567Z" fill="white" />
            <path className="logo__slogan" d="M204.242 60.3563V55.7139H205.337L205.441 56.0355C206.038 55.7631 206.612 55.6269 207.163 55.6269C208.293 55.6269 208.858 56.279 208.858 57.583V60.3563H207.545V57.9655C207.539 57.4381 207.473 57.0875 207.345 56.9136C207.224 56.7397 207 56.6528 206.676 56.6528C206.351 56.6528 205.975 56.7571 205.546 56.9658V60.3563H204.242Z" fill="white" />
            <path className="logo__slogan" d="M211.839 60.4432C211.497 60.4432 211.204 60.4084 210.961 60.3389C210.717 60.2693 210.494 60.1505 210.291 59.9824C210.094 59.8086 209.946 59.5623 209.848 59.2435C209.749 58.9189 209.7 58.519 209.7 58.0438C209.7 57.5569 209.749 57.1512 209.848 56.8267C209.946 56.5021 210.097 56.2558 210.3 56.0877C210.503 55.9138 210.723 55.795 210.961 55.7313C211.204 55.6617 211.497 55.6269 211.839 55.6269C212.354 55.6269 212.827 55.7429 213.256 55.9747V53.5405H214.56V60.3563H213.525L213.403 59.9477C213.021 60.278 212.499 60.4432 211.839 60.4432ZM213.021 56.9658C212.859 56.7513 212.569 56.6441 212.152 56.6441C211.734 56.6441 211.439 56.7339 211.265 56.9136C211.097 57.0933 211.013 57.47 211.013 58.0438C211.013 58.4379 211.056 58.7364 211.143 58.9392C211.236 59.1421 211.358 59.2754 211.508 59.3391C211.659 59.3971 211.873 59.4261 212.152 59.4261C212.551 59.4261 212.835 59.3159 213.004 59.0957C213.177 58.8755 213.264 58.5248 213.264 58.0438C213.264 57.5395 213.183 57.1802 213.021 56.9658Z" fill="white" />
            <path className="logo__slogan" d="M218.616 60.3563L217.469 55.7139H218.729L219.607 59.3478H219.677L220.703 56.0008H221.737L222.763 59.3478H222.832L223.702 55.7139H224.962L223.824 60.3563H222.041L221.215 57.5569L220.398 60.3563H218.616Z" fill="white" />
            <path className="logo__slogan" d="M225.628 60.3563V55.7139H226.932V60.3563H225.628ZM225.602 54.7663V53.6796H226.958V54.7663H225.602Z" fill="white" />
            <path className="logo__slogan" d="M227.814 56.7223V55.7139H228.64L228.779 54.5663H229.909V55.7139H231.405V56.7223H229.909V58.3133C229.909 58.7364 229.956 59.029 230.049 59.1913C230.147 59.3478 230.347 59.4261 230.648 59.4261C230.973 59.4261 231.289 59.3478 231.596 59.1913V60.1824C231.213 60.3563 230.79 60.4432 230.327 60.4432C229.179 60.4432 228.605 59.881 228.605 58.7566V56.7223H227.814Z" fill="white" />
            <path className="logo__slogan" d="M233.783 60.3563H232.479V53.5405H233.783V55.9747C234.322 55.7429 234.864 55.6269 235.409 55.6269C236.516 55.6269 237.078 56.2558 237.095 57.5135V60.3563H235.783V57.9221C235.777 57.4062 235.707 57.0672 235.574 56.9049C235.446 56.7368 235.226 56.6528 234.913 56.6528C234.583 56.6528 234.206 56.7513 233.783 56.9484V60.3563Z" fill="white" />
            <path className="logo__slogan" d="M242.135 60.4432C241.549 60.4432 241.115 60.307 240.831 60.0346C240.547 59.7564 240.402 59.287 240.396 58.6262V55.7139H241.7V58.1481C241.706 58.6755 241.764 59.0204 241.874 59.1826C241.99 59.3391 242.216 59.4174 242.552 59.4174C242.871 59.4174 243.245 59.3159 243.673 59.1131V55.7139H244.977V60.3563H243.882L243.786 60.052C243.189 60.3128 242.639 60.4432 242.135 60.4432Z" fill="white" />
            <path className="logo__slogan" d="M245.864 57.1049C245.864 56.1196 246.562 55.6269 247.959 55.6269C248.614 55.6269 249.295 55.7255 250.002 55.9225V56.9571C249.289 56.7426 248.576 56.6354 247.863 56.6354C247.626 56.6354 247.449 56.6673 247.333 56.731C247.223 56.789 247.168 56.9049 247.168 57.0788C247.168 57.2179 247.208 57.3193 247.289 57.3831C247.376 57.4468 247.565 57.4787 247.855 57.4787C248.15 57.4787 248.402 57.4874 248.611 57.5048C248.825 57.5222 249.043 57.5598 249.263 57.6178C249.489 57.6699 249.672 57.7453 249.811 57.8438C249.95 57.9423 250.063 58.0785 250.15 58.2524C250.237 58.4263 250.28 58.6349 250.28 58.8784C250.28 59.1971 250.228 59.4637 250.124 59.6782C250.019 59.8926 249.863 60.0549 249.654 60.165C249.445 60.2693 249.219 60.3418 248.976 60.3824C248.733 60.4229 248.44 60.4432 248.098 60.4432C247.292 60.4432 246.582 60.3273 245.968 60.0955V59.087C246.64 59.3188 247.379 59.4348 248.185 59.4348C248.486 59.4348 248.692 59.3971 248.802 59.3217C248.918 59.2464 248.976 59.116 248.976 58.9305C248.976 58.7566 248.901 58.6436 248.75 58.5915C248.605 58.5335 248.362 58.5045 248.02 58.5045C247.243 58.5045 246.69 58.406 246.359 58.2089C246.029 58.0061 245.864 57.6381 245.864 57.1049Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_1_37" x1="25.0062" y1="0.102358" x2="25.0062" y2="72.6924" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3CA42C" />
                    <stop offset="0.58125" stopColor="#008F2E" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_37" x1="25.0404" y1="1.90206" x2="25.0404" y2="57.4655" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3CA42C" />
                    <stop offset="0.58125" stopColor="#008F2E" />
                </linearGradient>
            </defs>
        </svg>

    )
};

export default LandMarkerLogo;