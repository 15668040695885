import { gql } from "@apollo/client";

const action = {
  getAllRvlContactRequest: gql`
    query getAllRvlContactRequest($limit: Int, $page: Int) {
      getAllRvlContactRequest(
        q: { limit: $limit, page: $page, order: { createdAt: -1 } }
      ) {
        data {
          id
          name
          address
          phone
          isDone
          description
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,
  updateRvlContactRequest: gql`
    mutation updateRvlContactRequest(
      $id: ID!
      $data: UpdateRvlContactRequestInput!
    ) {
      updateRvlContactRequest(id: $id, data: $data) {
        id
      }
    }
  `,
};

export default action;
