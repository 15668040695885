import { gql } from "@apollo/client";
const action = {
  getAllTopSearch: gql`
    query GetAllTopSearch($page: Int, $limit: Int) {
      getAllTopSearch(
        q: { page: $page, limit: $limit, order: { createdAt: -1 } }
      ) {
        data {
          id
          name
          createdAt
        }
        total
      }
    }
  `,
  deleteOneTopSearch: gql`
    mutation DeleteOneTopSearch($id: ID!) {
      deleteOneTopSearch(id: $id) {
        id
      }
    }
  `,
  createTopSearch: gql`
    mutation CreateTopSearch($data: String) {
      createTopSearch(data: { name: $data }) {
        id
      }
    }
  `,
};

export default action;
