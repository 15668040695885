import React, { Suspense } from "react";
import withMainLayout from "../../hocs/withLayout";

const Contentlayout = ({ children }) => {
  return (
    <Suspense fallback={null}>
      <div className="">{children}</div>
    </Suspense>
  );
};

export default withMainLayout(Contentlayout);
