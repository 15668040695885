import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

export const loadingSlice = createSlice({
  name: "rhNews",
  initialState,
  reducers: {
    setLoading(state, action) {
      if (action.payload === undefined) {
        state.loading = true;
      } else {
        state.loading = action.payload;
      }
    },
  },
  extraReducers: {},
});

export const { setLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
