import { gql } from "@apollo/client";

const action = {
  getProvince: gql`
    query getProvince {
      getProvince {
        id
        province
      }
    }
  `,
  getDistrict: gql`
    query getDistrict($provinceId: String!) {
      getDistrict(provinceId: $provinceId) {
        id
        district
      }
    }
  `,
  getWard: gql`
    query getWard($districtId: String!) {
      getWard(districtId: $districtId) {
        id
        ward
      }
    }
  `,
  suggestAdress: gql`
    query suggestAdress($latitude: Float!, $longitude: Float!) {
      suggestAdress(latitude: $latitude, longitude: $longitude) {
        address {
          id
          provinceId
          districtId
          wardId
        }
        street
      }
    }
  `,

  suggestHotelCode: gql`
    mutation suggestHotelCode($provinceId: String!) {
      suggestHotelCode(provinceId: $provinceId)
    }
  `,

  suggestTentCode: gql`
    mutation suggestTentCode($campingLocationId: ID!) {
      suggestTentCode(campingLocationId: $campingLocationId)
    }
  `,

  checkHotelCode: gql`
    mutation checkHotelCode($hotelCode: String!) {
      checkHotelCode(hotelCode: $hotelCode)
    }
  `,

  suggestCampingLocationCode: gql`
    mutation suggestCampingLocationCode($provinceId: String!) {
      suggestCampingLocationCode(provinceId: $provinceId)
    }
  `,

  checkCampingLocationCode: gql`
    mutation checkCampingLocationCode($campingLocationCode: String!) {
      checkCampingLocationCode(campingLocationCode: $campingLocationCode)
    }
  `,

  checkTentCode: gql`
    mutation checkTentCode($tentCode: ID!) {
      checkTentCode(tentCode: $tentCode)
    }
  `,
};
export default action;
