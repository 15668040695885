import { gql } from "@apollo/client";

const action = {
  getAllUser: gql`
    query GetAllUser(
      $name: String
      $page: Int
      $limit: Int
      $filter: Mixed
      $sort: Mixed
    ) {
      getAllUser(
        q: {
          search: $name
          page: $page
          limit: $limit
          filter: $filter
          order: $sort
        }
      ) {
        data {
          id
          name
          phone
          email
          role
          avatar
          companyId
          isFired
          status
        }
        total
      }
    }
  `,
  adminUpdatePointForUser: gql`
    mutation AdminUpdatePointForUser($userId: ID!, $point: Int!) {
      adminUpdatePointForUser(userId: $userId, point: $point) {
        id
        name
        phone
        uid
        email
        password
        role
        reputationScore
        avatar
        createdAt
        updatedAt
      }
    }
  `,
  getOneUser: gql`
    query GetOneUser($id: ID!) {
      getOneUser(id: $id) {
        id
        name
        phone
        uid
        email
        password
        totalPost
        role
        isBlock
        reputationScore
        avatar
        followerIds
        followers {
          name
          id
        }
        createdAt
        updatedAt
      }
    }
  `,
  blockUserByAdmin: gql`
    mutation blockUserByAdmin($userId: ID!) {
      blockUserByAdmin(userId: $userId) {
        id
      }
    }
  `,
  updateUserByAdmin: gql`
    mutation UpdateUserByAdmin(
      $id: ID!
      $name: String
      $email: String
      $password: String
      $isBlock: Boolean
    ) {
      updateUserByAdmin(
        id: $id
        data: {
          name: $name
          email: $email
          password: $password
          isBlock: $isBlock
        }
      ) {
        id
      }
    }
  `,
  registerUserByAdmin: gql`
    mutation registerUserByAdmin(
      $email: String!
      $phone: String!
      $password: String!
      $name: String!
      $avatar: String
      $address: String
      $description: String
    ) {
      registerUserByAdmin(
        email: $email
        phone: $phone
        password: $password
        name: $name
        avatar: $avatar
        address: $address
        description: $description
      ) {
        token
      }
    }
  `,
  getMe: gql`
    query {
      getMe {
        id
      }
    }
  `,
  moveToDepartment: gql`
    mutation moveToDepartment($userId: ID!, $departmentId: ID!) {
      moveToDepartment(userId: $userId, deparmentId: $departmentId) {
        id
      }
    }
  `,
  firedStaff: gql`
    mutation firedStaff($userId: ID!) {
      firedStaff(userId: $userId) {
        id
        isFired
      }
    }
  `,
  unFiredStaff: gql`
    mutation unFiredStaff($userId: ID!) {
      unFiredStaff(userId: $userId) {
        id
        isFired
      }
    }
  `,
  acceptStaff: gql`
    mutation acceptStaff($id: ID!) {
      acceptStaff(id: $id) {
        id
      }
    }
  `,
  denyStaff: gql`
    mutation denyStaff($id: ID!) {
      denyStaff(id: $id) {
        id
      }
    }
  `,
  updateMe: gql`
    mutation updateMe($data: UpdateUserInput!) {
      updateMe(data: $data) {
        id
        name
        phone
        role
        avatar
        devMode
      }
    }
  `,
};

export default action;
