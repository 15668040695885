import { gql } from "@apollo/client";

const action = {
  getAllStreamReport: gql`
    query GetAllStreamReport(
      $name: String
      $page: Int
      $limit: Int
      $filter: Mixed
    ) {
      getAllStreamReport(
        q: {
          search: $name
          page: $page
          limit: $limit
          filter: $filter
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          streamId
          userIds
          count
          type
          seen
          stream {
            id
          }
          users {
            id
            name
          }
        }
        total
      }
    }
  `,
};

export default action;
