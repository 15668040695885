import { gql } from "@apollo/client";

const action = {
  getAllIconProject: gql`
    query getAllIconProject($page: Int, $limit: Int) {
      getAllIconProject(
        q: { page: $page, limit: $limit, order: { createdAt: -1 } }
      ) {
        data {
          id
          key
          image
          createdAt
          updatedAt
        }
        total
      }
    }
  `,

  createIconProject: gql`
    mutation createIconProject($data: CreateIconProjectInput!) {
      createIconProject(data: $data) {
        id
      }
    }
  `,
  updateIconProject: gql`
    mutation updateIconProject($id: ID!, $data: UpdateIconProjectInput!) {
      updateIconProject(id: $id, data: $data) {
        id
      }
    }
  `,
  getAllIconPrjectWOPaging: gql`
    query {
      getAllIconPrjectWOPaging {
        id
        key
      }
    }
  `,
};

export default action;
