import { gql } from "@apollo/client";

const adressActions = {
  getProvince: gql`
    query {
      getProvince {
        id
        province
      }
    }
  `,

  getDistrict: gql`
    query getDistrict($provinceId: String!) {
      getDistrict(provinceId: $provinceId) {
        id
        district
      }
    }
  `,

  getWard: gql`
    query getWard($districtId: String!) {
      getWard(districtId: $districtId) {
        id
        ward
      }
    }
  `,

  suggestAdress: gql`
    query suggestAdress($latitude: Float!, $longitude: Float!) {
      suggestAdress(latitude: $latitude, longitude: $longitude) {
        address {
          id
          provinceId
          province
          districtId
          district
          wardId
          ward
        }
        street
      }
    }
  `,
};

export default adressActions;
