import React from "react";

export const userRoutes = [
  {
    exact: true,
    key: 'user-list',
    path: `/user-list`,
    component: React.lazy(() => import("../../pages/user-list/index.js")),
  },

  {
    exact: true,
    key: 'user-detail',
    path: `/user-detail/:slug`,
    component: React.lazy(() => import("../../pages/user-detail/index.js")),
  },
];
