import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { Layout, Nav } from "@douyinfe/semi-ui";

import routes from "../../../routes/routes";
import { SiderContents } from "./navbar-content";

import "./mainSider.scss";

export const selectedKeysByPathName = (pathName) => {
  const activeKey = routes.find((route) => {
    return route.path === pathName;
  });
  if (activeKey) return activeKey.key;
};

const { Sider } = Layout;

function MainSider({ onClick }) {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const { user: userData } = useSelector((state) => state.auth);

  const handleMenuItemClick = (menuItem) => {
    onClick?.();
    history.push(menuItem.path);
  };

  return (
    <Sider
      className="sideBar"
      style={{ backgroundColor: "var(--semi-color-bg-1)" }}
    >
      <Nav
        limitIndent={true}
        level={1}
        selectedKeys={[selectedKeysByPathName(pathname)]}
        key="sider-nav-bar"
        items={SiderContents.map((menu, index) => {
          return {
            itemKey: menu.key,
            text: menu.title,
            onClick: () => handleMenuItemClick(menu),
            items: menu?.permissions?.map((item, index) => {
              return {
                itemKey: item.key,
                text: item.title,
                onClick: () => handleMenuItemClick(item),
              };
            }),
          };
        })}
        footer={{
          collapseButton: false,
        }}
      />
    </Sider>
  );
}

export default memo(MainSider);
