import React from "react";
import { Button, Col, Form, Row, Spin } from "@douyinfe/semi-ui";

import "./authForm.scss";
import { IconCheckboxTick } from "@douyinfe/semi-icons";
import LandMarkerLogo from "../../../assets/images/logos/land-marker-logo";
import classNames from "classnames";

const Authform = ({ onSubmit, onChecked, loading, isSaved }) => {
  return (
    <div className="form-container">
      <div className="form-wrapper">
        <div className="form-header">
          <LandMarkerLogo />
        </div>

        <div className="form-box">
          <div className="form-content">
            <Form onSubmit={onSubmit} disabled={loading}>
              <Row>
                <Col span={24}>
                  <div className="form-content">
                    <Form.Input
                      field="username"
                      label="Tài khoản"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập tên tài khoản đăng nhập",
                        },
                      ]}
                    />
                    <Form.Input
                      field="password"
                      label="Mật khẩu"
                      mode="password"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập mật khẩu đăng nhập",
                        },
                      ]}
                    />
                  </div>
                  <div className={classNames([
                    "form-checkout-savedUserPassword",
                    {"disabled" : loading}
                  ])}>
                    <div className="check-input" onClick={onChecked}>
                      <div className={"check-icon " + (isSaved ? "checked" : "")}>
                        <IconCheckboxTick />
                      </div>
                    </div>
                    <span className="check-label">Lưu tài khoản</span>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    className="btn-margin-right form-login-button"
                  >
                    {loading ? <Spin /> : ""} <span>Đăng nhập</span>
                  </Button>
                </Col>
              </Row>
            </Form>

            <div className="form-overlay" />
          </div>
        </div>
      </div>

      <div className="container-overlay">
        <div className="form-overlay-sun" />
        <div className="form-overlay-sun" />
        <div className="form-overlay-sun" />
        <div className="form-overlay-sun" />
      </div>
    </div>
  );
};

export default Authform;
