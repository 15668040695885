import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Toast } from "@douyinfe/semi-ui";
import { getStoreAuthToken } from "../redux/auth/authStore";

const httpLink = new HttpLink({
  // uri: process.env.REACT_APP_RVGROUP_URL,
  uri: process.env.REACT_APP_RVECO_URL,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      "x-token": getStoreAuthToken(),
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Credentials': true
    },
  });

  return forward(operation);
});

const logoutLink = onError((data) => {
  if (data?.response?.errors?.length > 0) {
    window.location.reload();
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, logoutLink.concat(httpLink)),
});

export default client;
