import { gql } from "@apollo/client";

const action = {
  getAllDepartment: gql`
    query GetAllDepartment($page: Int, $limit: Int, $filter: Mixed) {
      getAllDepartment(
        q: {
          page: $page
          limit: $limit
          filter: $filter
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          name
          manager {
            id
            username
            phone
            email
            name
          }
          companyId
          company {
            id
            name
            image
          }
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,

  getOneDepartment: gql`
    query getOneDepartment($id: ID!) {
      getOneDepartment(id: $id) {
        name
        managerId
        companyId
      }
    }
  `,

  updateDepartment: gql`
    mutation UpdateDepartment($id: ID!, $data: UpdateDepartmentInput!) {
      updateDepartment(id: $id, data: $data) {
        id
      }
    }
  `,

  createDepartment: gql`
    mutation CreateDepartment($data: CreateDepartmentInput!) {
      createDepartment(data: $data) {
        id
      }
    }
  `,

  deleteOneDepartment: gql`
    mutation deleteOneDepartment($id: ID!) {
      deleteOneDepartment(id: $id) {
        id
      }
    }
  `,

  getAllDepartmentByCompany: gql`
    query GetAllDepartmentByCompany($companyId: ID!) {
      getAllDepartmentByCompany(companyId: $companyId) {
        id
        name
        company {
          name
        }
      }
    }
  `,
};

export default action;
