import client from "../../../config/apolloClient.riverHospitality";
import actionGraphQL from "../../../graphql";

const addressApis = {
  getProvince: () => {
    return client.query({
      query: actionGraphQL.address.getProvince,
      variables: {},
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },

  getDistrict: (provinceId) => {
    return client.query({
      query: actionGraphQL.address.getDistrict,
      variables: {
        provinceId,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },

  getWard: (districtId) => {
    return client.query({
      query: actionGraphQL.address.getWard,
      variables: {
        districtId,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },

  suggestAdress: ({ latitude, longitude }) => {
    return client.query({
      query: actionGraphQL.address.suggestAdress,
      variables: {
        latitude,
        longitude,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },

  suggestHotelCode: (provinceId) => {
    return client.mutate({
      mutation: actionGraphQL.address.suggestHotelCode,
      variables: {
        provinceId,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },

  checkCampingLocationCode: (campingLocationCode) => {
    return client.mutate({
      mutation: actionGraphQL.address.checkCampingLocationCode,
      variables: {
        campingLocationCode,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },

  suggestCampingLocationCode: (provinceId) => {
    return client.mutate({
      mutation: actionGraphQL.address.suggestCampingLocationCode,
      variables: {
        provinceId,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },
  suggestTentCode: (campingLocationId) => {
    return client.mutate({
      mutation: actionGraphQL.address.suggestTentCode,
      variables: {
        campingLocationId,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },

  checkHotelCode: (hotelCode) => {
    return client.mutate({
      mutation: actionGraphQL.address.checkHotelCode,
      variables: {
        hotelCode,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },

  checkTentCode: (tentCode) => {
    return client.mutate({
      mutation: actionGraphQL.address.checkTentCode,
      variables: {
        tentCode,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
  },
};

export default addressApis;
