import { gql } from "@apollo/client";
const action = {
  getAllCard: gql`
    query GetAllCard($page: Int, $limit: Int, $name: String) {
      getAllCard(
        q: {
          page: $page
          limit: $limit
          search: $name
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          image
          info
          name
          categoryId
          category {
            name
          }
          createdAt
        }
        total
      }
    }
  `,

  getOneCard: gql`
    query getOneCard($id: ID!) {
      getOneCard(id: $id) {
        id
        info
        categoryId
        image

        name
      }
    }
  `,

  updateCard: gql`
    mutation updateCard($id: ID!, $data: UpdateCardInput!) {
      updateCard(id: $id, data: $data) {
        id
      }
    }
  `,
  createCard: gql`
    mutation CreateCard($data: CreateCardInput!) {
      createCard(data: $data) {
        id
      }
    }
  `,
  deleteOneCard: gql`
    mutation deleteOneCard($id: ID!) {
      deleteOneCard(id: $id) {
        id
      }
    }
  `,
};

export default action;
