import { gql } from "@apollo/client";
const action = {
  getAllRvsStream: gql`
    query GetAllRvsStream($name: String, $page: Int, $limit: Int) {
      getAllRvsStream(
        q: {
          search: $name
          page: $page
          limit: $limit
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          name
          hashtag
          privacy
          userInvitedId
          userCreated {
            name
          }
          coverAvatar
          view
          popular
          sponsor
          dynamicLink {
            shortLink
          }
          status
          link
          userInvitedId
          createdAt
        }
        total
      }
    }
  `,
};

export default action;
