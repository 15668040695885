import { gql } from "@apollo/client";

const action = {
  getAllRgNews: gql`
    query GetAllRgNews($page: Int, $limit: Int) {
      getAllRgNews(
        q: { limit: $limit, page: $page, order: { createdAt: -1 } }
      ) {
        data {
          id
          title
          description
          content
          image
          createdAt
          updatedAt
          rgTypeNews {
            id
            name
          }
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,
  createRgNews: gql`
    mutation CreateRgNews($data: CreateRgNewsInput!) {
      createRgNews(data: $data) {
        id
      }
    }
  `,

  deleteOneRgNews: gql`
    mutation DeleteOneRgNews($id: ID!) {
      deleteOneRgNews(id: $id) {
        id
      }
    }
  `,

  getOneRgNews: gql`
    query getOneRgNews($id: ID!) {
      getOneRgNews(id: $id) {
        title
        content
        description
        image
        rgTypeNewsId
        rgTypeNews {
          id
          name
        }
      }
    }
  `,

  updateRgNews: gql`
    mutation updateRgNews($id: ID!, $data: UpdateRgNewsInput!) {
      updateRgNews(id: $id, data: $data) {
        id
      }
    }
  `,
  getAllRgTypeNewsWOPaging: gql`
    query {
      getAllRgTypeNewsWOPaging {
        id
        name
      }
    }
  `,
};

export default action;
