import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  authSlice,
  checkAuthToken,
  getStoreCurrentUser,
  setCurrentUser,
} from "../../../redux/auth/authStore";
import { getProvince } from "../../../redux/slices/RiverHospitality/AddressSlice";

const AuthTokenLogin = ({ children }) => {
  const dispatch = useDispatch();
  const router = useLocation();
  const { saveRedirectPath } = authSlice.actions;

  const userAuthInfo = useSelector((state) => state.auth.user);

  const checkLoginToken = () => {
    const currentUser = getUserData();
    dispatch(setCurrentUser(currentUser));
    dispatch(getProvince());
    dispatch(saveRedirectPath(router.pathname));
    dispatch(checkAuthToken());
  };

  const getUserData = () => {
    try {
      const currentUser = JSON.parse(getStoreCurrentUser());

      if (!currentUser) return null;

      return currentUser;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    if (userAuthInfo?.devMode) return;
    checkLoginToken();
  }, []);

  return <>{children}</>;
};

export default AuthTokenLogin;
