import { gql } from "@apollo/client";

const action = {
  getAllNews: gql`
    query getAllNews($page: Int, $limit: Int, $name: String) {
      getAllNews(
        q: {
          page: $page
          limit: $limit
          search: $name
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          content
          title
          type
          fromApp
          reviewImage
          userPost {
            name
          }
          newsCategory {
            name
          }
          createdAt
          updatedAt
        }
        total
      }
    }
  `,
  getOneNews: gql`
    query getOneNews($id: ID!) {
      getOneNews(id: $id) {
        id
        endpoint
        slug
        type
        newsCategoryId
        newsCategory {
          id
          name
        }
        slugId
        content
        description
        reviewImage
        userPostId
        view
        title
        isSaved
        departmentIds
        departmentNames
        userPost {
          id
          name
        }
        createdAt
        updatedAt
        fromApp
        userIds
        userNames
        companyIds
        companyNames
      }
    }
  `,

  createNews: gql`
    mutation createNews($data: CreateNewsInput!) {
      createNews(data: $data) {
        id
      }
    }
  `,
  updateNews: gql`
    mutation updateNews($id: ID!, $data: UpdateNewsInput!) {
      updateNews(id: $id, data: $data) {
        id
      }
    }
  `,
  deleteOneNews: gql`
    mutation deleteOneNews($id: ID!) {
      deleteOneNews(id: $id) {
        id
      }
    }
  `,
};

export default action;
