import { gql } from "@apollo/client";

const action = {
  getAllProjectTag: gql`
    query GetAllProjectTag($name: String, $page: Int, $limit: Int) {
      getAllProjectTag(
        q: {
          search: $name
          page: $page
          limit: $limit
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          rgbItemColor
          name
          createdAt
        }
        total
      }
    }
  `,

  updateProjectTag: gql`
    mutation UpdateProjectTag($id: ID!, $data: UpdateProjectTagInput!) {
      updateProjectTag(id: $id, data: $data) {
        id
      }
    }
  `,
  createProjectTag: gql`
    mutation CreateProjectTag($data: CreateProjectTagInput!) {
      createProjectTag(data: $data) {
        id
      }
    }
  `,

  getAllProjectTagWOPaging: gql`
    query {
      getAllProjectTagWOPaging {
        id
        name
        rgbItemColor
      }
    }
  `,
};

export default action;
