import { gql } from "@apollo/client";

const action = {
  getAllRhEventNews: gql`
    query GetAllRhEventNews(
      $page: Int
      $limit: Int
      $name: String
      $filter: Mixed
    ) {
      getAllRhEventNews(
        q: {
          limit: $limit
          page: $page
          search: $name
          order: { createdAt: -1 }
          filter: $filter
        }
      ) {
        data {
          id
          title
          description
          content
          image
          createdAt
          updatedAt
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,
  createRhEventNews: gql`
    mutation CreateRhEventNews($data: CreateRhEventNewsInput!) {
      createRhEventNews(data: $data) {
        id
      }
    }
  `,

  deleteOneRhEventNews: gql`
    mutation DeleteOneRhEventNews($id: ID!) {
      deleteOneRhEventNews(id: $id) {
        id
      }
    }
  `,

  getOneRhEventNews: gql`
    query GetOneRhEventNews($id: ID!) {
      getOneRhEventNews(id: $id) {
        title
        content
        description
        image
        createdAt
        updatedAt
      }
    }
  `,

  updateRhEventNews: gql`
    mutation updateRhEventNews($id: ID!, $data: UpdateRhEventNewsInput!) {
      updateRhEventNews(id: $id, data: $data) {
        title
        content
        description
        image
      }
    }
  `,
};

export default action;
