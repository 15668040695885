import { Toast } from "@douyinfe/semi-ui";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import authApi from "../../apis/auth/auth";
import constants from "../../helpers/constants/constants";

export const loginDatas = createAsyncThunk(
  "/auth/loginDatas",
  async ({ username, password }) => {
    try {
      const res = await authApi.login({ username, password });
      if (res?.errors?.length > 0) {
        throw res.errors[0].message;
      }
      return res;
    } catch (error) {
      Toast.error(error);
    }
  }
);

export const checkAuthToken = createAsyncThunk(
  "auth/checkAuthToken",
  async () => {
    try {
      const res = await authApi.checkLoginToken();

      if (res?.errors?.length > 0) {
        throw res.errors[0].message;
      }
      return res;
    } catch (error) {
      Toast.error("Hết hạn đăng nhập. Xin đăng nhập lại");
    }
  }
);

const initialState = {
  user: null,
  token: "",
  hasError: false,
  redirectSaved: "/",
  isSaveAuthInfo: true,
};

export const storeLocalAuthToken = (key, token, isExpiry) => {
  if (isExpiry) {
    Cookies.set(key, token, {
      expires: constants.EXPIRY_SAVE_AUTH_DATE,
    });
  } else {
    Cookies.set(key, token);
  }
};

export const getStoreCurrentUser = () => {
  try {
    const authStore = Cookies.get(constants.STORE_CURRENT_USER);
    if (!authStore) throw "";
    return authStore;
  } catch (error) {
    return error;
  }
};

export const getStoreAuthToken = () => {
  try {
    const authStore = Cookies.get(constants.STORE_NAME);
    if (!authStore) throw "";
    return authStore;
  } catch (error) {
    return error;
  }
};

export const removeAuthenticationStore = () => {
  Cookies.remove(constants.STORE_NAME);
  Cookies.remove(constants.STORE_CURRENT_USER);
};

export const authSlice = createSlice({
  name: "auths",
  initialState,
  reducers: {
    logOut(state) {
      Object.assign(state, initialState);
      removeAuthenticationStore();
    },
    saveRedirectPath(state, action) {
      state.redirectSaved = action.payload;
    },
    saveAuthInfoLogin(state, action) {
      state.isSaveAuthInfo = action.payload;
    },

    setCurrentUser(state, { payload }) {
      state.user = payload;
    },
  },
  extraReducers: {
    [loginDatas.pending]: (state, { payload }) => {
      state.hasError = false;
    },
    [loginDatas.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        const currentUserStore = JSON.stringify(payload.data.loginUser.user);
        if (state.isSaveAuthInfo) {
          storeLocalAuthToken(
            constants.STORE_NAME,
            payload.data.loginUser.token,
            true
          );
          storeLocalAuthToken(
            constants.STORE_CURRENT_USER,
            currentUserStore,
            true
          );
        } else {
          storeLocalAuthToken(constants.STORE_CURRENT_USER, currentUserStore);
          storeLocalAuthToken(
            constants.STORE_NAME,
            payload.data.loginUser.token
          );
        }
        state.user = payload.data.loginUser.user;
        state.token = payload.data.loginUser.token;
        state.hasError = false;
      } else {
        Object.assign(state, initialState);
        state.hasError = true;
      }
    },
    [loginDatas.failed]: (state, { payload }) => {
      state.user = null;
      state.token = "";
      state.hasError = true;
    },
    [checkAuthToken.fulfilled]: (state, { payload }) => {
      if (payload?.data) {
        state.user = payload.data.getMe;
        state.hasError = false;
      } else {
        state.user = null;
        state.token = "";
        state.hasError = true;
        removeAuthenticationStore();
      }
    },
    [checkAuthToken.failed]: (state, { payload }) => {
      state.user = null;
      state.token = "";
      state.hasError = true;
      removeAuthenticationStore();
    },
  },
});

export const { setCurrentUser } = authSlice.actions;

export default authSlice.reducer;
