import { gql } from "@apollo/client";

const action = {
  getAllWorkFlow: gql`
    query GetAllWorkFlow(
      $name: String
      $page: Int
      $limit: Int
      $filter: Mixed
    ) {
      getAllWorkFlow(
        q: {
          search: $name
          page: $page
          limit: $limit
          filter: $filter
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          title
          typeWorkFLow
          startTime
          endTime
          content
          status
          priority
          userCreated {
            name
          }
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,
};

export default action;
