import { gql } from "@apollo/client";

const action = {
  getAllRhHotel: gql`
    query getAllRhHotel(
      $page: Int
      $limit: Int
      $name: String
      $filter: Mixed
    ) {
      getAllRhHotel(
        q: {
          limit: $limit
          page: $page
          search: $name
          order: { createdAt: -1 }
          filter: $filter
        }
      ) {
        data {
          id
          hotelCode
          name
          address
          images
          price
          checkinTime
          checkoutTime
          location {
            coordinates
          }
          roomFeatures {
            name
          }
          roomRules {
            name
          }
          roomUtilities {
            name
          }
          typeRooms {
            name
          }
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,

  getOneRhHotel: gql`
    query getOneRhHotel($id: ID!) {
      getOneRhHotel(id: $id) {
        hotelCode
        name
        images
        address
        price
        description
        checkinTime
        checkoutTime
        roomFeatureIds
        roomRuleIds
        roomUtilityIds
        provinceId
        districtId
        wardId
        street
        location {
          coordinates
        }
        roomFeatures {
          name
          id
        }
        roomRules {
          name
          id
        }
        roomUtilities {
          name
          id
        }
      }
    }
  `,

  createRhHotel: gql`
    mutation createRhHotel($data: CreateRhHotelInput!) {
      createRhHotel(data: $data) {
        id
      }
    }
  `,

  deleteOneRhHotel: gql`
    mutation deleteOneRhHotel($id: ID!) {
      deleteOneRhHotel(id: $id) {
        id
      }
    }
  `,

  updateRhHotel: gql`
    mutation updateRhHotel($id: ID!, $data: UpdateRhHotelInput!) {
      updateRhHotel(id: $id, data: $data) {
        id
      }
    }
  `,

  // Room feature
  getAllRhHotelRoomFeature: gql`
    query getAllRhHotelRoomFeature($page: Int, $limit: Int, $name: String) {
      getAllRhHotelRoomFeature(
        q: {
          limit: $limit
          page: $page
          search: $name
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          name
          createdAt
          updatedAt
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,

  getOneRhHotelRoomFeature: gql`
    query getOneRhHotelRoomFeature($id: ID!) {
      getOneRhHotelRoomFeature(id: $id) {
        id
        name
      }
    }
  `,

  createRhHotelRoomFeature: gql`
    mutation createRhHotelRoomFeature($data: CreateRhHotelRoomFeatureInput!) {
      createRhHotelRoomFeature(data: $data) {
        id
      }
    }
  `,

  updateRhHotelRoomFeature: gql`
    mutation updateRhHotelRoomFeature(
      $id: ID!
      $data: UpdateRhHotelRoomFeatureInput!
    ) {
      updateRhHotelRoomFeature(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOneRhHotelRoomFeature: gql`
    mutation deleteOneRhHotelRoomFeature($id: ID!) {
      deleteOneRhHotelRoomFeature(id: $id) {
        id
      }
    }
  `,

  // Room Rule

  getAllRhHotelRoomRule: gql`
    query getAllRhHotelRoomRule($page: Int, $limit: Int, $name: String) {
      getAllRhHotelRoomRule(
        q: {
          limit: $limit
          page: $page
          search: $name
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          name
          createdAt
          updatedAt
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,

  getOneRhHotelRoomRule: gql`
    query getOneRhHotelRoomRule($id: ID!) {
      getOneRhHotelRoomRule(id: $id) {
        id
        name
      }
    }
  `,

  createRhHotelRoomRule: gql`
    mutation createRhHotelRoomRule($data: CreateRhHotelRoomRuleInput!) {
      createRhHotelRoomRule(data: $data) {
        id
      }
    }
  `,

  updateRhHotelRoomRule: gql`
    mutation updateRhHotelRoomRule(
      $id: ID!
      $data: UpdateRhHotelRoomRuleInput!
    ) {
      updateRhHotelRoomRule(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOneRhHotelRoomRule: gql`
    mutation deleteOneRhHotelRoomRule($id: ID!) {
      deleteOneRhHotelRoomRule(id: $id) {
        id
      }
    }
  `,

  // Room Utility

  getAllRhHotelUtility: gql`
    query getAllRhHotelUtility($page: Int, $limit: Int, $name: String) {
      getAllRhHotelUtility(
        q: {
          limit: $limit
          page: $page
          search: $name
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          name
          createdAt
          updatedAt
          iconName
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,

  getOneRhHotelUtility: gql`
    query getOneRhHotelUtility($id: ID!) {
      getOneRhHotelUtility(id: $id) {
        id
        name
        iconName
      }
    }
  `,

  createRhHotelUtility: gql`
    mutation createRhHotelUtility($data: CreateRhHotelUtilityInput!) {
      createRhHotelUtility(data: $data) {
        id
      }
    }
  `,

  updateRhHotelUtility: gql`
    mutation updateRhHotelUtility($id: ID!, $data: UpdateRhHotelUtilityInput!) {
      updateRhHotelUtility(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOneRhHotelUtility: gql`
    mutation deleteOneRhHotelUtility($id: ID!) {
      deleteOneRhHotelUtility(id: $id) {
        id
      }
    }
  `,

  // Hotel room book info
  getAllRhHotelRoomBookInfo: gql`
    query getAllRhHotelRoomBookInfo(
      $page: Int
      $limit: Int
      $filter: Mixed
      $name: String
    ) {
      getAllRhHotelRoomBookInfo(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: { createdAt: -1 }
          search: $name
        }
      ) {
        data {
          id
          hotelCode
          bookingCode
          checkinDate
          checkoutDate
          price
          name
          message
          userBooking {
            name
            phone
          }
          status
          hotel {
            name
          }
          typeRoom {
            name
          }
          paymentMethods
          status
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,
  acceptHotelRoomBook: gql`
    mutation acceptHotelRoomBook($id: ID!) {
      acceptHotelRoomBook(hotelRoomBookInfoId: $id) {
        id
      }
    }
  `,

  declineHotelRoomBook: gql`
    mutation declineHotelRoomBook($id: ID!) {
      declineHotelRoomBook(hotelRoomBookInfoId: $id) {
        id
      }
    }
  `,

  getAllRhHotelBookedLog: gql`
    query getAllRhHotelBookedLog($page: Int, $limit: Int, $filter: Mixed) {
      getAllRhHotelBookedLog(
        q: {
          limit: $limit
          page: $page
          order: { createdAt: -1 }
          filter: $filter
        }
      ) {
        data {
          id
          action
          updatedAt
          hotelRoomBookInfo {
            id
            name
            price
          }
          isPaid
          status
          userConfirm {
            name
            phone
          }
        }
        pagination {
          page
          limit
          total
        }
      }
    }
  `,

  getOneRhHotelRoomBookInfo: gql`
    query getOneRhHotelRoomBookInfo($id: ID!) {
      getOneRhHotelRoomBookInfo(id: $id) {
        name
        hotel {
          name
          address
          images
          location {
            coordinates
          }
          hotelCode
        }
        checkinDate
        checkoutDate
        price
        paymentMethods
        typeRoom {
          name
        }
        status
        userBooking {
          name
          phone
        }
      }
    }
  `,
};

export default action;
