import { gql } from "@apollo/client";
const action = {
  getAllCategory: gql`
    query GetAllCategory($page: Int, $limit: Int) {
      getAllCategory(
        q: { page: $page, limit: $limit, order: { createdAt: -1 } }
      ) {
        data {
          id
          image
          name
          createdAt
        }
        total
      }
    }
  `,
  getAllCategoryWOPaging: gql`
    query {
      getAllCategoryWOPaging {
        id
        image
        name
        createdAt
      }
    }
  `,
  updateCategory: gql`
    mutation UpdateCategory($id: ID!, $data: UpdateCategoryInput!) {
      updateCategory(id: $id, data: $data) {
        id
      }
    }
  `,
  createCategory: gql`
    mutation CreateCategory($name: String, $image: String) {
      createCategory(data: { name: $name, image: $image }) {
        id
      }
    }
  `,
};

export default action;
