import React from "react";
import { userRoutes } from "./user";
import { postRoutes } from "./project/project";
import { planningRoutes } from "./planning-information/planning";
import { paymentRoutes } from "./payment/payment";
import { newRoutes } from "./news/news";

const crmRoutes = [
  {
    key: "dashboard",
    exact: true,
    path: "/",
    component: React.lazy(() => import("../pages/dashboard/dashboard")),
  },
];

const routes = [
  ...crmRoutes,
  ...postRoutes,
  ...userRoutes,
  ...planningRoutes,
  ...paymentRoutes,
  ...newRoutes,
];

export default routes;
