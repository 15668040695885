import { gql } from "@apollo/client";

const action = {
  authorizeUser: gql`
    mutation authorizeUser(
      $userId: ID!
      $hosPermissionData: hosPermissionDataInPut
      $appRoles: [String]
    ) {
      authorizeUser(
        userId: $userId
        hosPermissionData: $hosPermissionData
        appRoles: $appRoles
      ) {
        id
      }
    }
  `,
};

export default action;
